import { E_QueryField, E_QueryType } from '@eureka/services/src/types/query';
import _ from 'lodash';
/**
 *
 * @param query
 * @returns 是否包含Spum字段
 */
export function hasSpumFieldInSrp(query) {
    return _.find(query, { field: E_QueryField.VF_SPUM });
}
/**
 *
 * @param query
 * @returns SRP 是否是spum搜索模式
 */
export function isSpumSearchInSrp(query) {
    const spumField = hasSpumFieldInSrp(query);
    if (spumField) {
        const pum = _.find(_.get(spumField, 'items', []), { field: 'MATERIAL' }) || {};
        const pumList = _.get(pum, 'items', []);
        const isSimplePum = pumList.length === 1;
        const firstPum = _.get(pumList, '[0].items', []);
        const firstPumRange = _.find(firstPum, { type: E_QueryType.RANGE }) || {};
        return isSimplePum && (firstPumRange?.from || firstPumRange?.to);
    }
    return false;
}
/**
 *
 * @param query
 * @returns 生成SPUM搜索文本一句话描述
 */
export function generateSpumSearchText(query) {
    const spumField = hasSpumFieldInSrp(query);
    if (spumField) {
        const pumClass = _.find(_.get(spumField, 'items', []), { field: 'VF_MATERIAL_CLASSIFICATION' }) || {};
        const pumClassName = _.get(pumClass, 'items[0].name');
        const pum = _.find(_.get(spumField, 'items', []), { field: 'MATERIAL' }) || {};
        const pumList = _.get(pum, 'items', []);
        const firstPum = _.get(pumList, '[0].items', []);
        const unitName = _.get(_.find(firstPum, { field: 'PUM_PROP_ID' }), 'items[0].name', '');
        const unitValue = _.get(_.find(firstPum, { field: 'PUM_UNIT' }), 'items[0].value', '');
        const firstPumRange = _.find(firstPum, { type: E_QueryType.RANGE }) || {};
        return `[${pumClassName} ${unitName}${firstPumRange.from}-${firstPumRange.to}${unitValue}]`;
    }
    return '';
}
