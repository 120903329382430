export class PwaService {
    // BeforeInstallPromptEvent
    installPromptEvent = null;
    installHook = (e) => { };
    init() {
        if (this.installPromptEvent)
            return;
        const installHook = (e) => {
            this.installPromptEvent = e;
            this.installPromptEvent.preventDefault();
        };
        this.installHook = installHook;
        window.addEventListener('beforeinstallprompt', installHook);
    }
    unload() {
        window.removeEventListener('beforeinstallprompt', this.installHook);
    }
    get isInPwa() {
        return window.matchMedia('(display-mode:minimal-ui) or (display-mode:standalone)').matches;
    }
}
export const pwaService = new PwaService();
export function trackParam(sourceType) {
    return {
        module_type: 'EUREKA',
        event_type: 'SS Pwa',
        source_type: sourceType,
    };
}
