export var E_RESULT_ROUTE;
(function (E_RESULT_ROUTE) {
    E_RESULT_ROUTE["STANDARD"] = "standard";
    E_RESULT_ROUTE["DESIGN"] = "design";
    E_RESULT_ROUTE["ANALYSIS"] = "analysis";
})(E_RESULT_ROUTE || (E_RESULT_ROUTE = {}));
export const resultRouters = [
    {
        path: '/results',
        component: () => import('@/presentation/pages/results/index.vue'),
        props(route) {
            const { query, name } = route;
            const { sort, page, limit, novelty_id, job_id, q, playbook, qid, efqid, image_id, solution_id, _type, timestamp, } = query;
            const updateInstanceQueryFlag = `
                ${name},
                ${novelty_id},
                ${job_id},
                ${sort},
                ${page},
                ${limit},
                ${q},
                ${playbook},
                ${qid},
                ${efqid},
                ${image_id},
                ${solution_id},
                ${_type},
                ${timestamp}
            `;
            return {
                updateInstanceQueryFlag,
            };
        },
        children: [
            {
                path: E_RESULT_ROUTE.STANDARD,
                name: E_RESULT_ROUTE.STANDARD,
                component: () => import('@/presentation/pages/results/search/SearchResult.vue'),
            },
            {
                path: E_RESULT_ROUTE.ANALYSIS,
                name: E_RESULT_ROUTE.ANALYSIS,
                component: () => import('@/presentation/pages/results/search/Analysis.vue'),
            },
            {
                path: E_RESULT_ROUTE.DESIGN,
                name: E_RESULT_ROUTE.DESIGN,
                component: () => import('@/presentation/pages/results/search/DesignResult.vue'),
            }
        ],
    }
];
