import { container } from '../container';
export class NoveltyApis {
    static semanticSearch(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/novelty/search/job`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    /**
     * 图像搜索 拿job_id
     */
    static desginSearch(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/image/search/job`,
            data,
        }).then(res => {
            return res.data;
        });
    }
}
