export var EActivityType;
(function (EActivityType) {
    EActivityType["default"] = "Default";
    EActivityType["invite"] = "Invite";
    EActivityType["inviteReview"] = "InviteReview";
    EActivityType["complete"] = "Complete";
    EActivityType["completeSuccess"] = "CompleteSuccess";
    EActivityType["feedback"] = "Feedback";
    EActivityType["feedbackSuccess"] = "FeedbackSuccess";
})(EActivityType || (EActivityType = {}));
export var EActivityTriggerType;
(function (EActivityTriggerType) {
    EActivityTriggerType["head"] = "HEAD";
    EActivityTriggerType["search"] = "SEARCH";
    EActivityTriggerType["search_result"] = "SEARCH_RESULT";
    EActivityTriggerType["search_result_top"] = "SEARCH_RESULT_TOP";
    EActivityTriggerType["search_result_list"] = "SEARCH_RESULT_LIST";
    EActivityTriggerType["search_summary"] = "SEARCH_SUMMARY";
    EActivityTriggerType["search_pum"] = "SEARCH_PUM";
    EActivityTriggerType["search_design"] = "SEARCH_DESIGN";
    EActivityTriggerType["pdf_view"] = "PDF_DOWNLOAD";
    EActivityTriggerType["technology"] = "CREATE_TECHNOLOGY_ROADMAP";
    EActivityTriggerType["idea"] = "IDEA";
    EActivityTriggerType["project"] = "CREATE_PROJECT";
    EActivityTriggerType["monitor"] = "CREATE_MONITOR";
    EActivityTriggerType["read_more_company"] = "READ_MORE_COMPANY";
    EActivityTriggerType["export"] = "EXPORT";
    EActivityTriggerType["copilot"] = "COPILOT";
    EActivityTriggerType["analysis"] = "ANALYSIS";
    EActivityTriggerType["patent_view"] = "PATENT_VIEW";
    EActivityTriggerType["paper_view"] = "PAPER_VIEW";
    EActivityTriggerType["history"] = "HISTORY";
    EActivityTriggerType["disclosure"] = "DISCLOSURE";
    EActivityTriggerType["more_disclosure"] = "MORE_DISCLOSURE";
    EActivityTriggerType["rewrite_disclosure"] = "REWRITE_DISCLOSURE";
})(EActivityTriggerType || (EActivityTriggerType = {}));
export var EActivityStatus;
(function (EActivityStatus) {
    EActivityStatus["unfinished"] = "UNFINISHED";
    EActivityStatus["unreceived"] = "UNRECEIVED";
    EActivityStatus["received"] = "RECEIVED";
})(EActivityStatus || (EActivityStatus = {}));
export var E_LanguageLong;
(function (E_LanguageLong) {
    E_LanguageLong["en"] = "en_US";
    E_LanguageLong["cn"] = "zh_CN";
    E_LanguageLong["tw"] = "zh_TW";
    E_LanguageLong["jp"] = "ja_JP";
})(E_LanguageLong || (E_LanguageLong = {}));
export var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes[ErrorCodes["SMS_ERROR"] = 90112022] = "SMS_ERROR";
    // 验证码错误
    ErrorCodes[ErrorCodes["SMS_CODE_ERROR"] = 9030119] = "SMS_CODE_ERROR";
    // 账号已存在
    ErrorCodes[ErrorCodes["SMS_MOBILE_EXITED"] = 9030129] = "SMS_MOBILE_EXITED";
    // 重复领取
    ErrorCodes[ErrorCodes["REWARD_RECEIVED"] = 90112023] = "REWARD_RECEIVED";
})(ErrorCodes || (ErrorCodes = {}));
