import Vue from 'vue';
import VueRouter from 'vue-router';
import { resultRouters } from './results.router';
import { materialRouters } from './material.router';
import { newsLetterRouter } from './newsletter.router';
import { pwaRouter } from './pwa.router';
import SearchLayout from '@/presentation/pages/SearchLayout.vue';
import { ProductCacheService } from '@eureka/services/src/product-cache-service';
import { EurekaProduct, EurekaSelectedModule } from '@eureka/utils/control';
import { E_HomeRouter } from '@/types';
import { userSelectAirService } from '@eureka/services/src/user-select-air-service';
import { Site, SLD } from '@patsnap-utils/biz';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        component: SearchLayout,
        redirect: 'home',
        children: [
            {
                path: 'home',
                redirect: 'home/chat',
                component: () => import(/* webpackChunkName: "universal" */ '../presentation/pages/universal/index.vue'),
                beforeEnter: async (to, from, next) => {
                    const savedProd = ProductCacheService.load();
                    if (savedProd === EurekaProduct.MATERIAL) {
                        return next({ path: '/material' });
                    }
                    const selectModule = userSelectAirService.getCacheSelected();
                    if (selectModule === EurekaSelectedModule.AIR) {
                        return window.open(new Site({ sld: SLD.EUREKA, pathname: 'air' }).href, '_self');
                    }
                    next();
                },
                children: [
                    {
                        path: 'search',
                        name: E_HomeRouter.GENERAL,
                        component: () => import(/* webpackChunkName: "general" */ '../presentation/pages/general/General.vue'),
                    },
                    {
                        path: 'chat',
                        name: E_HomeRouter.CHAT,
                        component: () => import(/* webpackChunkName: "chat" */ '../presentation/pages/chat/Copilot.vue'),
                    }
                ],
            },
            {
                path: 'proxy-disclosure-view',
                beforeEnter: (to) => {
                    window.location.href = `/view/#/fullText'figures/?patentId=${to.query.id}`;
                },
            },
            {
                path: 'user-setting',
                component: () => import(/* webpackChunkName: "universal" */ '../presentation/pages/user-package/Layout.vue'),
                children: [
                    {
                        path: 'amount',
                        name: 'amount',
                        component: () => import(/* webpackChunkName: "universal" */ '../presentation/pages/user-package/Amount.vue'),
                    },
                    {
                        path: 'package',
                        name: 'package',
                        component: () => import(/* webpackChunkName: "universal" */ '../presentation/pages/user-package/Package.vue'),
                    }
                ],
            },
            materialRouters,
            newsLetterRouter,
            pwaRouter
        ],
    },
    ...resultRouters,
    { path: '*', beforeEnter: () => { window.location.href = '/404'; } }
];
const router = new VueRouter({
    mode: 'history',
    routes,
});
// 解决跳转重复路由报错问题
const routerPush = router.push;
router.push = (path) => {
    return routerPush.call(router, path).catch(err => err);
};
export default router;
