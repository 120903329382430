import 'reflect-metadata';
import { Container, ContainerModule } from '@patsnap/tahiti-core/shared/inversify';
import { patsnapCopilotModule } from '@/services/patsnap-copilot-module';
import { Message } from '@patsnap/tahiti-core/common/message';
import { Logger } from '@patsnap/tahiti-core/common/logger';
import { Toast } from 'patsnap-biz';
export const container = new Container({
    defaultScope: 'Singleton',
    skipBaseClassChecks: true,
});
function base(bind) {
    bind(Message).toConstantValue(Toast);
    bind(Logger).toConstantValue(console);
}
container.load(new ContainerModule(base));
container.load(new ContainerModule(patsnapCopilotModule));
export function rebind(serviceIdentifier) {
    if (container.isBound(serviceIdentifier)) {
        container.unbind(serviceIdentifier);
    }
    return container.bind(serviceIdentifier);
}
