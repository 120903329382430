import { ApiResults } from '@eureka/services/src/apis/results.api';
import { NoveltyApis } from '@eureka/services/src/apis/novelty.api';
import { SmartApis } from '@eureka/services/src/apis/smart.api';
import router from '@/routers';
import { i18n } from '@/infrastructure/locales';
import { EResultsPattern } from '@eureka/services/src/types/results';
import { IPaperSortDefault, ISortDefault, PlaybookMapping, IDesignSortDefault } from '@/infrastructure/configs/results/constants.conf';
import { PlaybookQuery, Playbooks } from '@eureka/services/src/types/playbook';
import { ESourceType } from '@eureka/services/src/types/solution';
import _ from 'lodash';
import { query2tag } from '@eureka/services/src/query';
import { ResultPatternMap } from '@eureka/plugin-patent/src/components/config';
import { E_RESULT_ROUTE } from '@/routers/results.router';
export function decode(input) {
    const sortBy = input.sort || 'sdesc';
    const page = parseInt(`0${input.page}`) || 1;
    const limit = parseInt(`0${input.limit}`) || 20;
    return {
        sort: {
            field: sortBy,
        },
        page,
        limit,
        novelty_id: input.novelty_id || '',
        job_id: input.job_id || '',
        q: input.q || '',
        playbook: input.playbook,
        _type: input._type,
        qid: input.qid,
        efqid: input.efqid,
        image_id: input.image_id,
        solution_id: input.solution_id,
        mode: input.mode,
        source: input.source,
        ws: input.ws,
    };
}
export class PageResults {
    i18n = i18n;
    records = [];
    recommends = [];
    failed = false;
    total = 0;
    /**
     * 基础参数
     */
    sort = {
        options: [],
        selected: '',
    };
    novelty_id;
    job_id;
    q;
    finally_query;
    doc = '';
    doc_lang;
    pum_facet;
    page = 1;
    limit = 20;
    finish = false;
    playbook = Playbooks.SMART;
    _type = PlaybookQuery.SMART;
    key_words = [];
    qid;
    efqid;
    image_id = '';
    image_url = '';
    solution_id;
    ws;
    /**
     * 模式
     * 以图搜图时传值:matchByImage
     */
    mode;
    source;
    doc_s = null;
    loading = true;
    remind = false;
    usage_amounts = 0;
    total_amounts = 0;
    defaultOptions = ISortDefault.map(sortKey => {
        return {
            code: sortKey,
            label: this.i18n.t(`sorter.${sortKey}`),
        };
    });
    constructor() {
        this.sort = {
            options: this.defaultOptions,
            selected: '',
        };
    }
    get parameters() {
        return {
            sort: this.sort.selected || 'sdesc',
            page: parseInt(`0${this.page}`) || 1,
            limit: parseInt(`0${this.limit}`) || 20,
            novelty_id: this.novelty_id,
            job_id: this.job_id,
            q: this.q,
            playbook: this.playbook,
            _type: this._type,
            qid: this.qid,
            efqid: this.efqid,
            image_id: this.image_id,
            solution_id: this.solution_id,
            mode: this.mode,
            source: this.source,
        };
    }
    /**
     * 搜索结果页模式，用来配置菜单或者页面功能
     */
    get resultPatternState() {
        if (router.currentRoute.name === E_RESULT_ROUTE.ANALYSIS) {
            return EResultsPattern.ANALYSIS;
        }
        const page = _.get(ResultPatternMap, this.playbook);
        if (page === EResultsPattern.DESIGN) {
            return this._type === PlaybookQuery.UTILITY ? EResultsPattern.UTILITY : EResultsPattern.DESIGN;
        }
        return page;
    }
    init(parameters) {
        const { sort, page, limit, q, playbook, novelty_id, _type, job_id, qid, efqid, image_id, solution_id, mode, source, ws, } = decode(parameters);
        this.novelty_id = novelty_id;
        this.job_id = job_id;
        this.page = page;
        this.limit = limit;
        this.q = q;
        this.playbook = playbook || Playbooks.SMART;
        this._type = _type;
        this.sort.selected = sort.field;
        this.qid = qid;
        this.efqid = efqid;
        this.image_id = image_id;
        this.solution_id = solution_id;
        this.mode = mode;
        this.ws = ws;
        this.source = source;
    }
    encode() {
        return {
            /* 排序 */
            ...{ sort: this.sort.selected },
            /* 分页 */
            ...{
                page: `${this.page}`,
                limit: `${this.limit}`,
            },
            ...{
                novelty_id: this.novelty_id,
                job_id: this.job_id,
                q: this.q,
                qid: this.qid,
                efqid: this.efqid,
            },
            playbook: this.playbook,
            _type: this._type,
            image_id: this.image_id,
            solution_id: this.solution_id,
            mode: this.mode,
            source: this.source,
        };
    }
    it;
    updateURLParameters(routeName) {
        const $route = router.currentRoute;
        const $router = router;
        clearTimeout(this.it);
        this.it = window.setTimeout(() => {
            const parameters = this.encode();
            const _query = $route.query;
            const query = {
                ..._query,
                ...parameters,
            };
            $router.push({
                name: routeName || $route.name || '',
                params: {
                    ...$route.params,
                },
                query,
            });
        }, 200);
    }
    async searchPreview(book) {
        let q = this.doc;
        let q_s = this.doc_s;
        if (book === Playbooks.LITERATURE && this.doc_s) {
            // 这个逻辑有2个地方都需要修改，因为他们都不是直接走公共的search方法：1. 近期历史点击ttc，2. 专利/文献TabNav切换
            // 文献的标签化需要把结构化转成文本q，1.接口不支持 2.结构化的q会导致文献输入框能打开高级弹窗
            const tagValue = query2tag(this.doc_s);
            if (tagValue.type) {
                q = `${tagValue.type}:"${tagValue.value}"`;
                q_s = undefined;
            }
        }
        const { qid, playbook, novelty_id } = await SmartApis.previewPatentOrPaper(_.omit({
            playbook: book,
            q_s,
            q,
        }, q_s ? 'q' : 'q_s'));
        this.playbook = playbook || book;
        this.novelty_id = novelty_id || '';
        this.qid = qid;
        this._type = novelty_id ? PlaybookQuery.NOVELTY : PlaybookQuery.SMART;
        this.q = q;
        return { q, q_s, qid, playbook, novelty_id };
    }
    /**
     * 用于阻止左侧过滤渲染
     */
    jobIdloading = false;
    async getJobId() {
        const noveltyRes = await NoveltyApis.semanticSearch({
            novelty_id: this.novelty_id,
            playbook: this.playbook,
        });
        if (noveltyRes.finish && noveltyRes.job_id) {
            this.job_id = noveltyRes.job_id;
            this.updateURLParameters();
        }
        else if (noveltyRes.q) {
            try {
                const doc_s = JSON.parse(noveltyRes.q);
                this.doc_s = doc_s;
            }
            catch (e) {
                this.doc_s = undefined;
                this.doc = noveltyRes.q;
            }
        }
        return {
            job_id: noveltyRes.job_id,
            q: noveltyRes.q,
            q_s: this.doc_s,
        };
    }
    async count() {
        try {
            const { total, doc, doc_s, image_url } = await ApiResults.queryCount({
                ...this.parameters,
            });
            this.total = total;
            this.failed = false;
            this.loading = false;
            this.source = undefined;
            this.doc = doc;
            this.doc_s = doc_s;
            this.image_url = image_url;
            return {
                doc,
                doc_s,
                total,
            };
        }
        catch (error) {
            this.failed = true;
            this.total = 0;
            this.loading = false;
            this.source = undefined;
            throw error;
        }
    }
    // material paper 去重
    casPaperDeduplicate(records) {
        if (this.resultPatternState === EResultsPattern.MATERIAL_PAPER) {
            const regex = /^\d+$/;
            // material paper按照标题去重，去掉的那条是里面paper id是纯数字的
            const titleGroups = _.groupBy(records, record => record.TITLE);
            let removedIds = [];
            _.forEach(titleGroups, (group, k) => {
                if (group.length > 1) {
                    // 非cas的paper
                    const notCasItems = _.filter(group, item => {
                        return regex.test(item.SOLUTION_ID);
                    });
                    // 存在cas paper的话，去重
                    if (notCasItems.length < group.length) {
                        const notCasIds = _.map(notCasItems, 'SOLUTION_ID');
                        removedIds = removedIds.concat(notCasIds);
                    }
                }
            });
            return _.reject(records, record => _.includes(removedIds, record.SOLUTION_ID));
        }
        return records;
    }
    async query() {
        this.records = [];
        this.key_words = [];
        try {
            if (this.novelty_id && !this.job_id) {
                this.jobIdloading = true;
                const jobInfo = await this.getJobId().finally(() => {
                    this.jobIdloading = false;
                });
                if (!jobInfo.job_id && jobInfo.q) {
                    this.loading = false;
                    return {
                        doc: jobInfo.q,
                        doc_s: jobInfo.q_s,
                    };
                }
                return;
            }
            const { total, records, page, limit, doc, key_words, finally_query, remind, usage_amounts, total_amounts, doc_s, doc_lang, pum_facet, recommend_words, } = await ApiResults.queryList(this.parameters);
            this.total = total;
            this.limit = limit;
            this.page = page;
            this.records = this.casPaperDeduplicate(records);
            this.recommends = recommend_words;
            this.key_words = key_words;
            this.doc = doc;
            this.finally_query = finally_query;
            this.failed = false;
            this.loading = false;
            this.source = undefined;
            this.remind = remind || false;
            this.usage_amounts = usage_amounts || 0;
            this.total_amounts = total_amounts || 0;
            this.doc_s = doc_s;
            this.doc_lang = doc_lang;
            this.pum_facet = pum_facet;
            return {
                total,
                page,
                limit,
                records,
                doc,
                doc_s,
            };
        }
        catch (error) {
            this.failed = true;
            this.total = 0;
            this.records = [];
            this.recommends = [];
            this.key_words = [];
            this.loading = false;
            this.source = undefined;
            throw error;
        }
    }
}
export class PatentPageResults extends PageResults {
    sourceType = ESourceType.PATENT;
}
export class PaperPageResults extends PageResults {
    sourceType = ESourceType.PAPER;
    constructor() {
        super();
        const paperOptions = IPaperSortDefault.map(sortKey => {
            return {
                code: sortKey,
                label: this.i18n.t(`sorter.${sortKey}`),
            };
        });
        this.sort = {
            options: paperOptions,
            selected: '',
        };
    }
}
export class DesignPageResults extends PageResults {
    sourceType = ESourceType.DESIGN;
    remind = false;
    usage_amounts = 0;
    total_amounts = 0;
    designOptions = IDesignSortDefault.map(sortKey => {
        return {
            code: sortKey,
            label: this.i18n.t(`sorter.${sortKey}`),
        };
    });
    constructor(_type) {
        super();
        if (_type === PlaybookQuery.UTILITY) {
            this.sort = {
                options: this.designOptions,
                selected: '',
            };
        }
    }
    reconfigure() {
        this.sort = {
            ...this.sort,
            options: this._type === PlaybookQuery.UTILITY ? this.defaultOptions : this.designOptions,
        };
    }
    async getJobId() {
        const noveltyRes = await NoveltyApis.desginSearch({
            q: this.q,
            image_id: this.image_id,
            solution_id: this.solution_id,
            mode: this.mode,
            playbook: this.playbook,
            _type: this._type,
            source: this.source,
        });
        if (noveltyRes.job_id) {
            this.job_id = noveltyRes.job_id;
            this.updateURLParameters();
        }
        else if (noveltyRes.q || noveltyRes.image_url) {
            this.doc = noveltyRes.q;
            this.image_url = noveltyRes.image_url;
        }
        return {
            job_id: noveltyRes.job_id,
            q: noveltyRes.q,
            q_s: null,
        };
    }
    async query() {
        this.records = [];
        this.key_words = [];
        try {
            if (this.image_id && !this.job_id) {
                this.jobIdloading = true;
                const jobInfo = await this.getJobId().finally(() => {
                    this.jobIdloading = false;
                });
                if (!jobInfo.job_id && jobInfo.q) {
                    this.loading = false;
                    return {
                        doc: jobInfo.q,
                        doc_s: jobInfo.q_s,
                    };
                }
                return;
            }
            const { total, records, page, limit, doc, image_url, remind, usage_amounts, total_amounts, doc_s, doc_lang, pum_facet, } = await ApiResults.queryDesignList({
                ...this.parameters,
            });
            this.total = total;
            this.limit = limit;
            this.page = page;
            this.records = records;
            this.doc = doc;
            this.failed = false;
            this.image_url = image_url;
            this.loading = false;
            this.remind = remind || false;
            this.usage_amounts = usage_amounts || 0;
            this.total_amounts = total_amounts || 0;
            this.doc_s = doc_s;
            this.doc_lang = doc_lang;
            this.pum_facet = pum_facet;
            return {
                total,
                page,
                limit,
                records,
                doc,
                doc_s,
            };
        }
        catch (error) {
            this.failed = true;
            this.total = 0;
            this.records = [];
            this.loading = false;
            throw error;
        }
    }
}
let patentResultsInstance = null;
let paperResultsInstance = null;
let designResultsInstance = null;
export const getCurrentPageResultsInstance = () => {
    const playbook = router.currentRoute.query.playbook || Playbooks.SMART;
    const currentType = PlaybookMapping[playbook] || ESourceType.PATENT;
    const _type = router.currentRoute.query._type || PlaybookQuery.DESIGN;
    switch (currentType) {
        case ESourceType.PAPER: {
            if (!paperResultsInstance) {
                paperResultsInstance = new PaperPageResults();
            }
            return paperResultsInstance;
        }
        case ESourceType.DESIGN: {
            if (!designResultsInstance) {
                designResultsInstance = new DesignPageResults(_type);
            }
            return designResultsInstance;
        }
        default: {
            if (!patentResultsInstance) {
                patentResultsInstance = new PatentPageResults();
            }
            return patentResultsInstance;
        }
    }
};
