import { trim, get, pickBy, identity, pick } from 'lodash';
import { SmartApis } from '@eureka/services/src/apis/smart.api';
import { Toast } from 'patsnap-biz';
import { generateSimpleQuery, isSimpleQuery, extractWsInQuery, query2tag } from '@eureka/services/src/query';
import { PlaybookQuery, Playbooks } from '@eureka/services/src/types/playbook';
import { getLocalDesignSearchType } from '@eureka/search-inputs/src/utils';
import { ESource } from '@eureka/services/src/types/solution';
import { hookErrorCode } from '@eureka/services/src/custom-error-handler';
import { withFieldLoading } from '@eureka/utils/task';
import { EActivityTriggerType } from '@eureka/services/src/types/activity-type';
import qs from 'qs';
export var E_ErrorCode;
(function (E_ErrorCode) {
    E_ErrorCode[E_ErrorCode["TEXT_PREVIEW_LIMIT_ERROR"] = 90112011] = "TEXT_PREVIEW_LIMIT_ERROR";
    E_ErrorCode[E_ErrorCode["IMAGE_PREVIEW_LIMIT_ERROR"] = 90112012] = "IMAGE_PREVIEW_LIMIT_ERROR";
})(E_ErrorCode || (E_ErrorCode = {}));
export const PATENT_SEARCH_MODE = {
    SMART: 'SMART',
    SEMANTIC: 'SEMANTIC',
    IMAGE: Playbooks.DESIGN,
};
export const SEARCH_MODE_MAPPING = {
    [Playbooks.SMART]: Playbooks.SMART,
    [Playbooks.NOVELTY]: Playbooks.SMART,
    [Playbooks.LITERATURE]: Playbooks.LITERATURE,
    [Playbooks.LITERATURENOVELTY]: Playbooks.LITERATURE,
    [Playbooks.DESIGN]: Playbooks.DESIGN,
};
export const SEMANTIC_QUERY_MIN_LENGTH = 200;
/**
 * 搜索模式支持的搜索命令
 */
export const COMMAND_TAG_PLAYBOOK_MAP = {
    [Playbooks.SMART]: ['ANCS', 'ADC', 'TTC', 'BENEFIT_PHRASE'],
    // 文献仅支持技术主题
    [Playbooks.LITERATURE]: ['TTC'],
    [Playbooks.DESIGN]: [''],
};
export class InputUniversalService {
    get mode() {
        if (this.isImageSearchPanelVisible || this.imageUrl)
            return Playbooks.DESIGN;
        // @ts-ignore
        const currentPlaybook = get(this.router, 'currentRoute.query.playbook') || Playbooks.SMART;
        /**
         * 1、尽管在图像搜索结果页, 重新搜索也不能算作图像搜索
         * 2、只有在this.isImageSearchPanelVisible || this.imageUrl时，是图像搜索
         * 3、专利tab搜索，是专利简单；文献tab搜索，是文献简单搜索
         */
        return currentPlaybook !== Playbooks.DESIGN ? currentPlaybook : Playbooks.SMART;
    }
    query = '';
    configPermission = null;
    submitting = false;
    searchFrom = null;
    $t(key, params) {
        return `${key}${params}`;
    }
    router = null;
    isStructure = false;
    structureQuery = null;
    get trimQuery() {
        return trim(this.query);
    }
    get trimQueryLength() {
        return this.trimQuery.length;
    }
    get isDesign() {
        return this.mode === Playbooks.DESIGN;
    }
    validate() {
        const requiredMsg = this.$t('unversalSearch.requiredError');
        if (this.trimQueryLength > 20000) {
            Toast({
                type: 'error',
                message: this.$t('unversalSearch.maxQueryLengthError', { length: '20,000' }),
            });
            return true;
        }
        if (this.isDesign) {
            if (this.trimQueryLength > 15000) {
                Toast({
                    type: 'error',
                    message: this.$t('unversalSearch.maxQueryLengthError', { length: '15,000' }),
                });
                return true;
            }
            if (!this.trimQueryLength && !this.imageUrl) {
                Toast({
                    type: 'error',
                    message: requiredMsg,
                });
                return true;
            }
        }
        else if (!this.trimQueryLength && !this.structureQuery) {
            Toast({
                type: 'error',
                message: requiredMsg,
            });
            return true;
        }
    }
    /**
     * 预处理 preview 的参数
     */
    handlePreviewParams(data) {
        return data;
    }
    search(fn) {
        if (this.validate())
            return false;
        this.submitting = true;
        const playbook = SEARCH_MODE_MAPPING[this.mode];
        /**
         * 用户在1分钟之内检索相同文本或图像，不消耗客户检索用量。
         * image_id和patent_id为了给后端来hash判断是否相同
         */
        const previewParams = {
            playbook: this.searchFrom || playbook,
            q: this.trimQuery,
            q_s: this.mode === Playbooks.DESIGN ? undefined : this.structureQuery,
            image_id: this.imageId,
        };
        const params = this.handlePreviewParams(previewParams);
        const task = SmartApis.preview(params)
            .then((data) => {
            this.submitting = false;
            const { q, qid, playbook, novelty_id } = data;
            const query = {
                playbook,
                q,
                qid,
                novelty_id,
                _type: this.isDesign ? this.designType : novelty_id ? PlaybookQuery.NOVELTY : PlaybookQuery.SMART,
                image_id: this.isDesign ? this.imageId : undefined,
                solution_id: this.solutionId,
                mode: this.isDesign && this.solutionId ? 'matchByImage' : undefined,
                source: this.isDesign ? this.source : undefined,
                page: 1,
                ws: this.isStructure ? (extractWsInQuery(this.structureQuery).length ? '1' : '') : '',
                timestamp: Date.now() + '',
            };
            const routeName = this.isDesign ? 'design' : 'standard';
            const params = pickBy(query, identity);
            const path = qs.stringify(params);
            this.isImageSearchPanelVisible = false;
            /**
             * 清除从历史记录进入的playbook标记
             */
            this.searchFrom = null;
            const url = `/results/${routeName}?${path}`;
            if (fn) {
                fn({ path: url });
            }
            else {
                this.router?.push(url);
            }
        });
        const task2 = hookErrorCode(task, [E_ErrorCode.TEXT_PREVIEW_LIMIT_ERROR, E_ErrorCode.IMAGE_PREVIEW_LIMIT_ERROR], (error) => {
            import('@eureka/invitation-reward/src/inviation.service').then(({ invitationServiceInstance }) => {
                invitationServiceInstance.init(EActivityTriggerType.search, error);
            });
        });
        withFieldLoading(this, task2, 'submitting');
    }
    updateSearchInput(data) {
        this.isStructure = !isSimpleQuery(data.q_s);
        // @ts-ignore
        this.structureQuery = data.q_s;
        this.query = data.q;
        /**
         * 图像相关信息
         */
        this.imageId = data.image_id;
        this.imageUrl = data.image_url;
        this.solutionId = data.solution_id;
        this.isImageSearchPanelVisible = false;
        this.source = data.image_id ? ESource.IMAGE_HISTORY : undefined;
        /**
         * 在图像搜索srp页面，更新类型为当前url中的_type
         */
        const _type = get(this.router, 'currentRoute.query._type', '');
        // @ts-ignore
        this.designType = [PlaybookQuery.DESIGN, PlaybookQuery.UTILITY].includes(_type) ? _type : getLocalDesignSearchType() || PlaybookQuery.DESIGN;
    }
    suggestionAction(item) {
        const canAutoSearch = item.isStructure || false;
        this.isStructure = canAutoSearch;
        if (canAutoSearch && item.isStructure) {
            this.structureQuery = item.structureType ? generateSimpleQuery(item.structureType, item.value, item.id, item.name) : pick(item, ['items', 'type', 'logic']);
            this.query = '';
        }
        else {
            this.structureQuery = null;
            this.query = item.value;
        }
        if (this.mode === Playbooks.LITERATURE && this.structureQuery) {
            // 这个逻辑有2个地方都需要修改，因为他们都不是直接走公共的search方法：1. 近期历史点击ttc（ISuggestionDisplayItem参数会丢失query的结构化，需要在调generateSimpleQuery后转换！），2. 专利/文献TabNav切换
            // 文献的标签化需要把结构化转成文本q，1.接口不支持 2.结构化的q会导致文献输入框能打开高级弹窗
            const tagValue = query2tag(this.structureQuery);
            if (tagValue.type) {
                this.query = `${tagValue.type}:"${tagValue.value}"`;
                this.structureQuery = null;
                this.isStructure = false;
            }
        }
    }
    /** 图像检索 */
    imageId;
    imageUrl = '';
    isImageSearchPanelVisible = false;
    // @ts-ignore
    designType = get(this.router, 'currentRoute.query._type') || getLocalDesignSearchType() || PlaybookQuery.DESIGN;
    solutionId;
    source;
    resetSearchInput() {
        this.updateSearchInput({
            q: '',
            q_s: undefined,
            image_url: '',
            image_id: '',
            solution_id: '',
        });
    }
    toggleImageSearchMode() {
        this.isImageSearchPanelVisible = !this.isImageSearchPanelVisible;
    }
}
export const InputUniversalServiceInstance = new InputUniversalService();
