import { Site, SLD } from '@patsnap-utils/biz';
/**
 *
 * @param id
 * @returns company link
 */
export function generateCompanyHref(id) {
    if (!id)
        return;
    return new Site({ sld: SLD.EUREKA, pathname: `company#/?id=${id}` }).href;
}
export function generateCompanyPath() {
    return new Site({ sld: SLD.EUREKA, pathname: 'company#/' }).href;
}
