import _ from 'lodash';
import { multiLineTooltipFormatter, createAxisLabelGraphic } from '@/infrastructure/utils/analysis-chart';
import { E_ANALYSIS_CHART } from './charts';
import { i18n } from '@/infrastructure/locales';
export var EWorkFlow;
(function (EWorkFlow) {
    EWorkFlow["POLYMER_PPI_IDS"] = "POLYMER_PPI_IDS";
    EWorkFlow["METAL_PPI_IDS"] = "METAL_PPI_IDS";
    EWorkFlow["CERAMIC_PPI_IDS"] = "CERAMIC_PPI_IDS";
})(EWorkFlow || (EWorkFlow = {}));
class ChartOption {
    constructor(visual) {
        this.visual = visual;
        this.chart = visual.chart;
        this.dataset = visual.dataset;
        this.extra = this.dataset?.extra;
        this.cutaways = this.dataset?.cutaways;
        this.option = this.chart?.getOption?.();
        if (!this.option)
            return;
        /**
         * 获取图表配置项：
         * 1. grid; legend; graphic; tooltip 返回的是数组
         * 2. series 返回的为series对象
         */
        this.grid = this.option?.grid?.[0];
        this.legend = this.option?.legend?.[0];
        this.graphic = this.option?.graphic?.[0];
        this.tooltip = this.option?.tooltip?.[0];
        this.series = this.option?.series;
    }
    visual;
    chart;
    dataset;
    extra;
    cutaways;
    option;
    legend;
    grid;
    graphic;
    series;
    tooltip;
    getTitle() {
        return '';
    }
    update() { }
}
export class StackColumnOption extends ChartOption {
    update() {
        const gridBottom = _.get(this.grid, 'bottom', 0);
        const option = {
            xAxisPadding: `${gridBottom / 2}px`,
            series: this.series.map((item, index) => {
                return {
                    ...item,
                    name: this.cutaways[1][index].code,
                };
            }),
            tooltip: {
                formatter: (params) => {
                    const data = _.map(params, item => {
                        return {
                            ...item,
                            seriesName: _.find(this.cutaways[1], { code: item.seriesName })?.name,
                        };
                    });
                    return multiLineTooltipFormatter(data);
                },
            },
            legend: {
                data: this.legend.data.map(item => {
                    return {
                        ...item,
                        name: item.code,
                    };
                }),
                formatter: (code) => {
                    const label = _.find(this.cutaways[1], { code })?.name;
                    return _.truncate(label);
                },
                tooltip: {
                    formatter: ({ name }) => {
                        const dynamicKey = _.find(Object.keys(EWorkFlow), item => this.extra[item]);
                        const workFlow = _.find(this.extra[dynamicKey], { code: name });
                        const parentFlow = _.get(workFlow, 'parent_names', []);
                        let tooltip = '';
                        _.forEach(parentFlow, (item, index) => {
                            tooltip += `<p style='margin-left: ${index * 14}px'>${item}</p>`;
                        });
                        return tooltip;
                    },
                },
                itemWidth: 16,
                left: 'center',
                bottom: 4,
            },
        };
        this.visual.setEchartsOption(option);
    }
}
export class TreeMapOption extends ChartOption {
    update() {
        const series = this.series.map(item => {
            return {
                ...item,
                itemStyle: {
                    gapWidth: 5,
                    borderRadius: [4, 4, 4, 4],
                },
            };
        });
        this.chart.setOption({ series });
    }
}
export class MultipleScatterOption extends ChartOption {
    update() {
        const title = `${_.get(this.extra, 'QUERY_PROPERTY.property_name', '')}:${_.get(this.extra, 'QUERY_PROPERTY.unit', '')}`;
        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = title;
        const yAxisTitle = tempDiv.textContent || tempDiv.innerText;
        tempDiv?.remove?.();
        tempDiv = null;
        const option = {
            tooltip: {
                extraCssText: 'max-width: 360px;max-height:400px;box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3); text-align: left; overflow-y: auto; overflow-x: auto;white-space: normal;z-index: 99;border-color: rgb(254,254,254) !important;',
                formatter: (params) => {
                    const propertySet = _.get(this.extra, 'PROPERTY_KEY', []);
                    const patentSet = _.get(this.extra, 'PATENT_ID', []);
                    const patent = _.find(patentSet, { code: params.data[4] });
                    const property = _.find(propertySet, { code: params.data[2] });
                    if (params.data[1] === '-')
                        return;
                    return `<div>
                        <span class='rounded bg-eureka-500 px-3 py-1 text-white'>${property?.label}：${property?.measurement?.value_str}${property?.unit}</span>
                        <p class="font-semibold mt-4 mb-3">${patent.label}</p>
                        <p class='text-grey-450'>${patent.title}</p>
                    </div>`;
                },
            },
            graphic: createAxisLabelGraphic({
                text: yAxisTitle,
                fontSize: 12,
                left: 12,
                rotation: Math.PI / 2,
                bottom: 'center',
            }),
        };
        this.chart.setOption(option);
    }
}
/**
 * material srp 专利列表视图 材料性能趋势分析
 */
export class MaterialSrpStackColumnOption extends ChartOption {
    update() {
        const option = {
            series: this.series.map((item, index) => {
                return {
                    ...item,
                    name: this.cutaways[1][index].code,
                };
            }),
            tooltip: {
                formatter: (params) => {
                    const data = _.map(params, item => {
                        return {
                            ...item,
                            seriesName: _.find(this.cutaways[1], { code: item.seriesName })?.name,
                        };
                    });
                    return multiLineTooltipFormatter(data);
                },
            },
            legend: {
                show: false,
            },
            graphic: [
                ...createAxisLabelGraphic({
                    text: i18n.t('searchResult.patentCount'),
                    fontSize: 12,
                    left: 'center',
                    rotation: 0,
                    bottom: 5,
                })
            ],
            grid: [
                {
                    top: 10,
                    bottom: 20,
                }
            ],
        };
        this.chart.setOption(option);
    }
}
export class LineOption extends ChartOption {
    getTitle() {
        const title = `${_.get(this.extra, 'QUERY_PROPERTY.property_name', '')}(${_.get(this.extra, 'QUERY_PROPERTY.unit', '')})`;
        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = title;
        const propertyTitle = tempDiv.textContent || tempDiv.innerText;
        tempDiv?.remove?.();
        tempDiv = null;
        return propertyTitle;
    }
    update() {
        const xAxisTitle = createAxisLabelGraphic({
            text: this.getTitle(),
            fontSize: 12,
            left: 'center',
            rotation: 0,
            bottom: 5,
        });
        const option = {
            series: this.series.map(item => {
                return {
                    ...item,
                    smooth: true,
                };
            }),
            grid: [
                {
                    top: 20,
                    left: 40,
                    bottom: 20,
                }
            ],
            graphic: [
                ...createAxisLabelGraphic({
                    text: i18n.t('searchResult.patentCount'),
                    fontSize: 12,
                    left: 20,
                    rotation: Math.PI / 2,
                    bottom: 'center',
                }),
                ...xAxisTitle
            ],
        };
        this.chart.setOption(option);
    }
}
export const getChartOption = (visual, chartType) => {
    switch (chartType) {
        case E_ANALYSIS_CHART.MATERIAL_FEATURE_EVALUATION:
            return new MultipleScatterOption(visual);
        case E_ANALYSIS_CHART.MATERIAL_PROPERTY_DISTRIBUTION:
            return new TreeMapOption(visual);
        case E_ANALYSIS_CHART.MATERIAL_PREPARATION_PROCESS_STUDY:
            return new StackColumnOption(visual);
        case E_ANALYSIS_CHART.MATERIAL_SRP_PROCESS_STUDY:
            return new MaterialSrpStackColumnOption(visual);
        case E_ANALYSIS_CHART.MATERIAL_PROPERTY_RANGE:
        case E_ANALYSIS_CHART.MATERIAL_SRP_PROPERTY_RANGE:
            return new LineOption(visual);
        default:
            return new ChartOption(visual);
    }
};
