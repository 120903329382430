import { ESourceType } from '@eureka/services/src/types/solution';
import { PlaybookQuery, Playbooks } from '@eureka/services/src/types/playbook';
import { EBenefitTab } from '@/types/result';
export const ISortDefault = ['sdesc', 'desc', 'cdesc'];
export const IPaperSortDefault = ['sdesc', 'pdasc', 'pddesc', 'ccasc', 'ccdesc'];
export const IDesignSortDefault = ['sdesc', 'desc'];
export const IResultTabs = [ESourceType.PATENT, ESourceType.PAPER];
/**
 * PROPS默认值
 */
export const PROP_DEFAULTS = {
    TRUE: () => true,
    FALSE: () => false,
    /**
     * 空数组
     */
    EMPTY_ARRAY: () => [],
    EMPTY_STRING: () => '',
    /**
     * 空方法
     */
    EMPTY_FUNCTION: () => () => { },
    EMPTY_ASYNC_FUNCTION: () => () => Promise.resolve(),
    /**
     * 空对象
     */
    EMPTY_OBJECT: () => ({}),
};
/**
 * 顶部输入框内容已从后端获取到
 */
export const EVENT_INPUT_READY = 'EVENT_INPUT_READY';
/**
 * playbook映射
 */
export const PlaybookMapping = {
    [Playbooks.SMART]: ESourceType.PATENT,
    [Playbooks.NOVELTY]: ESourceType.PATENT,
    [Playbooks.MATERIAL]: ESourceType.PATENT,
    [Playbooks.MATERIALNOVELTY]: ESourceType.PATENT,
    [Playbooks.LITERATURE]: ESourceType.PAPER,
    [Playbooks.LITERATUREMATERIAL]: ESourceType.PAPER,
    [Playbooks.LITERATURENOVELTY]: ESourceType.PAPER,
    [Playbooks.LITERATUREMATERIAL]: ESourceType.PAPER,
    [Playbooks.LITERATUREMATERIALNOVELTY]: ESourceType.PAPER,
    [Playbooks.DESIGN]: ESourceType.DESIGN,
};
/**
 * 图像搜索-动态渲染组件
 */
export const DesignComponentMapping = {
    [PlaybookQuery.DESIGN]: 'ThumbDesignResult',
    [PlaybookQuery.UTILITY]: 'SearchResult',
};
/**
 * 左侧过滤 - 技术功效tab
 */
export const BENEFIT_TABS = [EBenefitTab.KEY, EBenefitTab.ALL];
/**
 * 基础版免费条目数
 */
export const MAX_FREE_ITEM_COUNT = 40;
