import _ from 'lodash';
import { searchInitDeferred } from '@/services/deferreds';
import { EurekaProduct, controlDeferred } from '@eureka/utils/control';
import { ALL_MATERIAL_PLAYBOOKS } from '@eureka/utils/material';
import { isSpumSearchInSrp } from '@/infrastructure/utils/is-spum-search-in-srp';
export var E_LimitedReason;
(function (E_LimitedReason) {
    E_LimitedReason[E_LimitedReason["material"] = 0] = "material";
    E_LimitedReason[E_LimitedReason["freemium"] = 1] = "freemium";
    E_LimitedReason[E_LimitedReason["backend"] = 2] = "backend";
    E_LimitedReason[E_LimitedReason["userOperation"] = 3] = "userOperation";
})(E_LimitedReason || (E_LimitedReason = {}));
class LimitationService {
    constructor() {
    }
    // 获取被禁用的项目
    async getLimitedItemsRaw() {
        const [{ acl }, { freemium, chat, ai_disclosure, product }] = await Promise.all([
            searchInitDeferred.promise,
            controlDeferred.promise
        ]);
        return {
            // 分析视图的2张以后图表
            analysisChart: freemium ? [E_LimitedReason.freemium] : [],
            // 编辑material搜索框
            materialSearchBarEdit: product !== EurekaProduct.MATERIAL ? [E_LimitedReason.material] : [],
            // 左侧过滤
            leftFilter: [],
            // 分页
            pagination: freemium ? [E_LimitedReason.freemium] : [],
            // 高级搜索
            advancedSearchClickable: [...(!acl.advanced_search ? [E_LimitedReason.backend] : [])],
            advancedSearchVisible: [...(product === EurekaProduct.MATERIAL ? [E_LimitedReason.material] : [])],
            // AI交底书
            aiDisclosure: !ai_disclosure ? [E_LimitedReason.backend] : [],
            // 批量保存
            batchSave: !acl.batch_save ? [E_LimitedReason.backend] : [],
            chat: !chat ? [E_LimitedReason.backend] : [],
        };
    }
    async getLimitedItems() {
        const limitationRaw = await this.getLimitedItemsRaw();
        return _.mapValues(limitationRaw, (value) => !_.isEmpty(value));
    }
    async isLimitedBy(key, reason) {
        const info = await this.getLimitedItemsRaw();
        return _.includes(info[key], reason);
    }
}
class PlaybookLimitationService extends LimitationService {
    limitations;
    playbook;
    constructor(limitations, playbook) {
        super();
        this.limitations = limitations;
        this.playbook = playbook;
    }
    async getLimitedItemsRaw() {
        const limits = await super.getLimitedItemsRaw();
        let materialPlaybookWithoutMaterialPermission = false;
        if (ALL_MATERIAL_PLAYBOOKS.includes(this.playbook)) {
            const { product } = await controlDeferred.promise;
            if (product !== EurekaProduct.MATERIAL) {
                materialPlaybookWithoutMaterialPermission = true;
            }
        }
        return {
            ...limits,
            pagination: [...limits.pagination, ...(materialPlaybookWithoutMaterialPermission ? [E_LimitedReason.material] : [])],
            materialSearchBarEdit: [...limits.materialSearchBarEdit, ...(materialPlaybookWithoutMaterialPermission ? [E_LimitedReason.material] : [])],
            leftFilter: [...limits.leftFilter, ...(materialPlaybookWithoutMaterialPermission ? [E_LimitedReason.material] : [])],
        };
    }
}
class QueryLimitationService extends LimitationService {
    limitations;
    query;
    constructor(limitations, query) {
        super();
        this.limitations = limitations;
        this.query = query;
    }
    async getLimitedItemsRaw() {
        const limits = await super.getLimitedItemsRaw();
        const hasSpumChart = isSpumSearchInSrp(this.query);
        return {
            ...limits,
            analysisChartSPUM: hasSpumChart ? [] : [E_LimitedReason.userOperation],
        };
    }
}
export const limitationServiceInstance = new LimitationService();
export function generalLimitation() {
    return limitationServiceInstance;
}
export function playbookLimitation(playbook) {
    return new PlaybookLimitationService(limitationServiceInstance, playbook);
}
export function queryLimitation(query) {
    return new QueryLimitationService(limitationServiceInstance, query);
}
