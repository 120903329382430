import { onBeforeMount, ref } from 'vue';
import { SLD, Site } from '@patsnap-utils/biz';
import { createNanoEvents } from './nanoevent';
import { controlDeferred } from './control';
export const bannerEvent = createNanoEvents();
export function useBanner() {
    const empoweredMaterialTrial = ref(false);
    function openOverview() {
        if (empoweredMaterialTrial.value) {
            const url = `${new Site({ sld: SLD.EUREKA, pathname: 'user-setting/package' }).href}?open_update_auto=2&source_type=banner`;
            window.open(url, '_blank', 'noopener');
            return;
        }
        const link = `${new Site({ sld: SLD.EUREKA, pathname: 'overview' }).href}/?source_type=button`;
        window.open(link, '_blank', 'noopener');
    }
    onBeforeMount(async () => {
        const { empowered_material_trial } = await controlDeferred.promise;
        empoweredMaterialTrial.value = empowered_material_trial;
    });
    return {
        bannerEvent,
        openOverview,
    };
}
