// ref: https://confluence.zhihuiya.com/pages/viewpage.action?pageId=124543238
export var E_ActivityType;
(function (E_ActivityType) {
    /**
     * 邀请注册
     */
    E_ActivityType["inviteRegister"] = "inviteRegisterV2";
    /**
     * 反馈
     */
    E_ActivityType["inviteFeedback"] = "inviteFeedback";
    /**
     * 完善信息
     */
    E_ActivityType["perfectInformation"] = "perfectInformation";
})(E_ActivityType || (E_ActivityType = {}));
export var E_ActivityStatus;
(function (E_ActivityStatus) {
    E_ActivityStatus["unfinished"] = "UNFINISHED";
    E_ActivityStatus["unreceived"] = "UNRECEIVED";
    E_ActivityStatus["received"] = "RECEIVED";
})(E_ActivityStatus || (E_ActivityStatus = {}));
/**
 * 发送验证码
 */
export var E_MobileVerifyCodePurpose;
(function (E_MobileVerifyCodePurpose) {
    E_MobileVerifyCodePurpose["LOGIN"] = "LOGIN";
    E_MobileVerifyCodePurpose["LOGIN_REGISTER"] = "LOGIN_REGISTER";
    E_MobileVerifyCodePurpose["MOBILE_NUMBER_BINDING"] = "MOBILE_NUMBER_BINDING";
    E_MobileVerifyCodePurpose["UNIONID_BINDING"] = "UNIONID_BINDING";
    E_MobileVerifyCodePurpose["PASSWORD_FORGOT"] = "PASSWORD_FORGOT";
    E_MobileVerifyCodePurpose["REGISTER"] = "REGISTER";
    E_MobileVerifyCodePurpose["SSO_USER_PROFILE"] = "SSO_USER_PROFILE";
    E_MobileVerifyCodePurpose["SMS_EUREKA"] = "SMS_EUREKA";
    E_MobileVerifyCodePurpose["SMS_MOBILE_NUMBER_UPDATE"] = "SMS_MOBILE_NUMBER_UPDATE";
    E_MobileVerifyCodePurpose["SMS_SALES_LEAD"] = "SMS_SALES_LEAD";
})(E_MobileVerifyCodePurpose || (E_MobileVerifyCodePurpose = {}));
/**
 * 套餐版本
 */
export var EUserPackage;
(function (EUserPackage) {
    EUserPackage["GENERAL_FREEMIUM"] = "GENERAL_FREEMIUM";
    EUserPackage["GENERAL_TRIAL"] = "GENERAL_TRIAL";
    EUserPackage["GENERAL_ENTERPRISE"] = "GENERAL_ENTERPRISE";
    EUserPackage["GENERAL_UNIVERSITY"] = "GENERAL_UNIVERSITY";
    EUserPackage["MATERIAL_TRIAL"] = "MATERIAL_TRIAL";
    EUserPackage["MATERIAL_ENTERPRISE"] = "MATERIAL_ENTERPRISE";
    // ... 其他套餐
    EUserPackage["NEV_TRIAL"] = "NEV_TRIAL";
    EUserPackage["NEV_ENTERPRISE"] = "NEV_ENTERPRISE";
})(EUserPackage || (EUserPackage = {}));
