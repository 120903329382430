/**
 * 数据来源
 */
export var ESourceType;
(function (ESourceType) {
    /**
     * 专利
     */
    ESourceType["PATENT"] = "PATENT";
    /**
     * 文献
     */
    ESourceType["PAPER"] = "PAPER";
    /**
     * 图像搜索
     */
    ESourceType["DESIGN"] = "DESIGN";
})(ESourceType || (ESourceType = {}));
export var ESolutionTagSource;
(function (ESolutionTagSource) {
    ESolutionTagSource["TTC"] = "TTC";
    ESolutionTagSource["BENEFIT_PHRASE"] = "BENEFIT_PHRASE";
    ESolutionTagSource["NONE"] = "";
})(ESolutionTagSource || (ESolutionTagSource = {}));
/**
 * 拓展字段
 */
export var ESource;
(function (ESource) {
    ESource["IMAGE_HISTORY"] = "image_history";
    ESource["SORT"] = "sort";
    ESource["PAGE"] = "page";
})(ESource || (ESource = {}));
