import _ from 'lodash';
import { E_QueryLogic, E_QueryType } from '@eureka/services/src/types/query';
// 有items的query节点，比如range是没有的
export const typesWithItems = [
    E_QueryType.BOOL,
    E_QueryType.KEYWORD,
    E_QueryType.FIELD
];
// 只有一个block
function isPlainQuery(q) {
    if (!q)
        return true;
    return q.items.length === 1;
}
// 剥去前端只有关键字block下生成的多余括号
export function queryOptimize(q) {
    if (!q)
        return q;
    if (isPlainQuery(q) && q.items[0].type === E_QueryType.BOOL) {
        return q.items[0];
    }
    return q;
}
export function blocks2Query(blocks) {
    const items = _.map(blocks, (block) => {
        if (_.includes(typesWithItems, block.type) && !block.items?.length)
            return null;
        if (block.field === 'KEYWORD') {
            // 转成语义
            return {
                ...block,
                field: 'TEXT',
                items: _.map(block.items, (item, index) => _.omit({
                    ...item,
                    type: E_QueryType.KEYWORD,
                    logic: index === 0 ? E_QueryLogic.AND : item.logic,
                }, ['field'])),
            };
        }
        else {
            if (block.type === E_QueryType.RANGE)
                return block;
            return {
                ...block,
                items: _.map(block.items, (item, index) => _.omit({
                    ...item,
                    type: E_QueryType.KEYWORD,
                    logic: index === 0 ? E_QueryLogic.AND : item.logic,
                }, ['field'])),
            };
        }
    });
    const compactedItems = _.compact(items);
    return queryOptimize({
        logic: E_QueryLogic.AND,
        type: E_QueryType.BOOL,
        items: compactedItems,
    });
}
// 通过字段&值生成一个最简单query结构
export function generateSimpleQuery(field, data) {
    const { value, name } = data;
    return blocks2Query([{
            logic: E_QueryLogic.AND,
            type: E_QueryType.FIELD,
            field,
            items: [{
                    logic: E_QueryLogic.AND,
                    type: E_QueryType.KEYWORD,
                    value,
                    name,
                }],
        }]);
}
