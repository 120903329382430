// import { IntentErrorRendererContribution } from '@analytics/patsnap-copilot/src/intent-error-renderer-contribution';
// import { IntentLengthLimitRendererContribution } from '@analytics/patsnap-copilot/src/intent-length-limit-renderer-contribution';
// import { IntentLimitRendererContribution } from '@analytics/patsnap-copilot/src/intent-limit-renderer-contribution';
import { PatsnapCopilotIntentRendererContribution, PatsnapCopilotIntentRendererRegistry, PatsnapCopilotIntentRendererRegistryImpl, } from '@analytics/patsnap-copilot/src/patsnap-copilot-renderer';
import { PatsnapCopilotService } from '@analytics/patsnap-copilot/src/patsnap-copilot-service.interface';
import { SrpCopilotServiceImpl } from './srp-copilot-service';
import { PatsnapCopilotMessageSender, PatsnapCopilotMessageSenderImpl, } from '@analytics/patsnap-copilot/src/patsnap-copilot-message-sender';
import { VueBaseExternalIntentRenderer, VueBaseExternalIntentRendererImpl, } from '@analytics/patsnap-copilot/src/vue-base-external-intent-renderer';
import { WuJieBus } from '@analytics/patsnap-copilot/src/wujie-bus';
import { bindContributionProvider } from '@patsnap/tahiti-core/common/contribution-provider';
import WujieVue from 'wujie-vue2';
// import { PatsnapCopilotUsageService } from '@analytics/patsnap-copilot/src/patsnap-copilot-usage-service';
// import { PatsnapCopilotUsageServiceImpl } from '@eureka/services/src/patsnap-copilot-usage-service';
// import { CopilotChatUsageServiceImpl } from '@eureka/services/src/copilot-chat-usage-service';
import { PatsnapCopilotFeedBackService, PatsnapCopilotFeedBackServiceImpl, } from '@eureka/services/src/patsnap-copilot-feedback-service';
import { I18nService } from './i18n-service';
import { i18n } from '@/infrastructure/locales';
export function patsnapCopilotModule(bind) {
    // #region 以下代码是复制自 @analytics/patsnap-copilot/src/patsnap-copilot-module.ts
    const { bus } = WujieVue;
    bind(WuJieBus).toConstantValue(bus);
    bind(PatsnapCopilotMessageSender).to(PatsnapCopilotMessageSenderImpl);
    bind(VueBaseExternalIntentRenderer).to(VueBaseExternalIntentRendererImpl);
    bindContributionProvider(bind, PatsnapCopilotIntentRendererContribution);
    bind(PatsnapCopilotIntentRendererRegistry).to(PatsnapCopilotIntentRendererRegistryImpl);
    bind(I18nService).toConstantValue(i18n);
    // #endregion
    // #region 以下代码是项目中的代码
    // bind(PatsnapCopilotUsageService).to(PatsnapCopilotUsageServiceImpl);
    // bind(PatsnapCopilotUsageService).to(CopilotChatUsageServiceImpl);
    bind(PatsnapCopilotFeedBackService).to(PatsnapCopilotFeedBackServiceImpl);
    bind(PatsnapCopilotService).to(SrpCopilotServiceImpl);
    // bind(PatsnapCopilotIntentRendererContribution).to(IntentLimitRendererContribution);
    // bind(PatsnapCopilotIntentRendererContribution).to(IntentLengthLimitRendererContribution);
    // bind(PatsnapCopilotIntentRendererContribution).to(IntentErrorRendererContribution);
    // #endregion
}
