import { __decorate } from "tslib";
import { injectable } from '@patsnap/tahiti-core/shared/inversify';
import { container } from './container';
export const PatsnapCopilotFeedBackService = Symbol('PatsnapCopilotFeedBackService');
let PatsnapCopilotFeedBackServiceImpl = class PatsnapCopilotFeedBackServiceImpl {
    send(data) {
        return container.RnDHttp.post({
            headers: {
                'X-API-Version': '1.0',
            },
            data,
            url: `${process.env.VUE_APP_CORE_BASIC_SERVICE_URI}/core-copilot-api/analytics/copilot/feedback`,
        }).then(() => { });
    }
};
PatsnapCopilotFeedBackServiceImpl = __decorate([
    injectable()
], PatsnapCopilotFeedBackServiceImpl);
export { PatsnapCopilotFeedBackServiceImpl };
