import { E_HomeRouter } from '@/types';
import { ProductCacheService } from '@eureka/services/src/product-cache-service';
import { EurekaProduct, controlDeferred } from '@eureka/utils/control';
export const materialRouters = {
    path: 'material',
    component: () => import('@/presentation/pages/material/index.vue'),
    beforeEnter: async (to, from, next) => {
        const savedProd = ProductCacheService.load();
        savedProd === EurekaProduct.MATERIAL ? next() : next({ replace: true, path: '/home' });
    },
    children: [
        {
            path: '/',
            redirect: 'chat',
            component: () => import('@/presentation/pages/material/home.vue'),
            children: [
                {
                    path: 'search',
                    name: E_HomeRouter.MATERIAL_GENERAL,
                    component: () => import(/* webpackChunkName: "materialGeneral" */ '@/presentation/pages/material/general.vue'),
                },
                {
                    path: 'chat',
                    name: E_HomeRouter.MATERIAL_CHAT,
                    component: () => import(/* webpackChunkName: "chat" */ '@/presentation/pages/chat/Copilot.vue'),
                }
            ],
        },
        {
            path: 'substance',
            name: 'substance',
            component: () => import(/* webpackChunkName: "materialSubstance" */ '@/presentation/pages/material/substance/Substance.vue'),
        },
        {
            path: 'workflow',
            name: 'workflow',
            component: () => import(/* webpackChunkName: "materialWorkflow" */ '@/presentation/pages/material/workflow/Workflow.vue'),
        },
        {
            path: 'property',
            name: 'property',
            component: () => import(/* webpackChunkName: "propertyWorkflow" */ '@/presentation/pages/material/property/Property.vue'),
        },
        {
            path: 'metal',
            name: 'metal',
            component: () => import(/* webpackChunkName: "propertyWorkflow" */ '@/presentation/pages/material/metal/Metal.vue'),
        },
        {
            path: 'structure',
            name: 'structure',
            beforeEnter: async (to, from, next) => {
                const { material_structure } = await controlDeferred.promise;
                material_structure ? next() : next({ replace: true, path: '/home' });
            },
            component: () => import(/* webpackChunkName: "structure" */ '@/presentation/pages/material/structure/Structure.vue'),
        }
    ],
};
