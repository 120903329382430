import 'reflect-metadata';
import Vue from 'vue';
import App from './presentation/App.vue';
import router from './routers';
import '@patsnap-ui/design-tokens/scss/_reboot.scss';
import './presentation/assets/css/global.scss';
import { pwaService } from '@eureka/utils/pwa';
import { startup } from '@eureka/startup';
import { i18n, loadI18n } from './infrastructure/locales';
import { hackZIndex } from './infrastructure/utils/hack-z-index';
import { refreshToken } from './infrastructure/utils/refresh-token-from-inno';
import { getCurrentLanguage } from '@eureka/language-tool';
import '@patsnap-biz/design-tokens/scss/_button.scss';
import '@patsnap-biz/design-tokens/scss/_flex.scss';
import '@patsnap-biz/design-tokens/scss/_form.scss';
import '@patsnap-biz/design-tokens/scss/_input.scss';
import '@patsnap-biz/design-tokens/scss/_tooltip.scss';
import '@patsnap-biz/design-tokens/scss/_el-tooltip.scss';
import { updateTokenBaseRequestHeaders } from '@eureka/startup/src/enable-token-base';
import { ApiResults } from '@eureka/services/src/apis/results.api';
import { searchInitDeferred, DEFAULT_INIT } from './services/deferreds';
import { registerHttpErrorTooltip } from './infrastructure/utils/register-http-error-tooltip';
import { headerService } from './services/header.service';
import { accountDeferred } from '@eureka/utils/account';
import { container } from './inversify.config';
import { setContainer } from '@patsnap/tahiti-core/ioc/inject';
import { InputUniversalServiceInstance } from '@eureka/search-inputs/src/input-universal/input-universal-service';
pwaService.init();
hackZIndex();
refreshToken();
const locale = getCurrentLanguage();
updateTokenBaseRequestHeaders({ 'X-Site-Lang': locale?.toString().toUpperCase() });
InputUniversalServiceInstance.$t = (key, ...rest) => {
    return i18n.t(key, ...rest);
};
setContainer(container);
registerHttpErrorTooltip(i18n);
Vue.config.productionTip = false;
startup({
    headerService,
    projectName: 'eureka',
    preset: {
        name: 'eureka',
    },
    pageInit() {
        return ApiResults.pageInit().then((_) => {
            searchInitDeferred.resolve(_);
            return _;
        }).catch(() => {
            searchInitDeferred.resolve(DEFAULT_INIT);
        });
    },
});
(async () => {
    const i18n = await loadI18n(locale).then((i18n) => {
        accountDeferred.promise.then(() => {
            import('@eureka/startup/src/help-center').then(({ getHelpCenter }) => {
                getHelpCenter(i18n, headerService);
            });
        });
        return i18n;
    });
    new Vue({
        router,
        i18n,
        provide: {
            eventHub: new Vue(),
        },
        render: h => h(App),
    }).$mount('#app');
})();
