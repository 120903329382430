import { ProductCacheService } from '@eureka/services/src/product-cache-service';
import { EurekaProduct } from '@eureka/utils/control';
import { query2ResultParams } from '@eureka/services/src/query';
import { Playbooks } from '@eureka/services/src/types/playbook';
import { objectToQueryParams } from '@eureka/utils/transform-url';
export const newsLetterRouter = {
    path: 'redirect-query',
    name: 'redirect-query',
    beforeEnter: async (to, from, next) => {
        const field = to.query.field || '';
        const id = to.query.id || '';
        const value = to.query.value || '';
        const name = to.query.name || '';
        const savedProd = ProductCacheService.load();
        const playbook = (savedProd === EurekaProduct.MATERIAL ? Playbooks.MATERIAL : Playbooks.SMART);
        const params = await query2ResultParams(playbook, field, value, id, name);
        if (!params) {
            next({
                replace: true,
                path: '/home',
            });
            return;
        }
        const queryString = objectToQueryParams(params);
        const url = `/results/standard?${queryString}`;
        next({
            replace: true,
            path: url,
        });
    },
};
