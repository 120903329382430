var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { PatsnapCopilotIntentRendererRegistry } from '@analytics/patsnap-copilot/src/patsnap-copilot-renderer';
import { CopilotPlan } from '@analytics/patsnap-copilot/src/patsnap-copilot-service.interface';
import { Message } from '@patsnap/tahiti-core/common/message';
import { Site, SLD } from '@patsnap-utils/biz';
import { inject, injectable } from '@patsnap/tahiti-core/shared/inversify';
import { container } from '@eureka/services';
import { EurekaProduct, controlDeferred } from '@eureka/utils/control';
import { generateViewHref, generatePaperViewHref } from '@eureka/utils/view';
import { generateCompanyHref } from '@eureka/utils/company';
import { getCurrentLanguage } from '@eureka/language-tool';
import { CommonApis } from '@eureka/services/src/apis/common.api';
import { PatsnapCopilotFeedBackService } from './patsnap-copilot-feedback-service';
import { SmartApis } from './apis/smart.api';
import { Playbooks } from './types/playbook';
import { objectToQueryParams } from '@eureka/utils/transform-url';
import { generateSimpleQuery } from '@eureka/utils/block-to-query';
let AbstractEurekaCopilotService = class AbstractEurekaCopilotService {
    toast;
    _intentRendererRegistry;
    _feedbackService;
    patsnapCopilotUsage = {
        enabled: false,
        currentAmount: 0,
        maxAmount: 0,
        upgradeInvite: false,
        plan: CopilotPlan.A,
    };
    container = container;
    placeholder = '';
    webControl = true;
    jobId = null;
    setJobId(id) {
        this.jobId = id;
    }
    getJobInfo() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/copilot/job`;
        const data = {
            socket_type: this.getSocketType(),
            job_id: this.jobId,
        };
        return container.RnDHttp.post({
            url,
            data,
        }).then(res => res.data);
    }
    getSocketPath() {
        return this.getJobInfo().then((info) => {
            const { job_id, sock_path } = info;
            this.setJobId(job_id);
            const path = sock_path.replace('*', job_id);
            return `${process.env.VUE_APP_EUREKA_SOCKET_URI}/eureka${path}`;
        });
    }
    track(data) {
        CommonApis.track(data);
    }
    disableFeedback = false;
    feedback(_data) {
        const { product, ...data } = _data;
        return this._feedbackService.send({
            product: this.source || product || '',
            ...data,
        });
    }
    generateViewHref(data) {
        return generateViewHref(data);
    }
    generatePaperViewHref(data) {
        return generatePaperViewHref(data);
    }
    generateCompanyHref(context) {
        return generateCompanyHref(context.id);
    }
    showResultLink(context) {
        return context?.product === 'eureka' && context?.query_item;
    }
    async search(query, type, forceSemantic = false) {
        const { product } = await controlDeferred.promise;
        let params;
        if (product === EurekaProduct.MATERIAL) {
            params = {
                q_s: generateSimpleQuery('KEYWORD', { value: query }),
                playbook: type === 'patent' ? Playbooks.MATERIAL : Playbooks.LITERATUREMATERIAL,
            };
        }
        else {
            params = {
                q: query,
                playbook: type === 'patent' ? Playbooks.SMART : Playbooks.LITERATURE,
            };
        }
        const queryResult = await (forceSemantic ? SmartApis.previewSemantic : SmartApis.preview)(params);
        const queryString = objectToQueryParams(queryResult);
        const url = `${new Site({ sld: SLD.EUREKA, pathname: 'results/standard' }).href}/?${queryString}`;
        window.open(url, '_blank', 'noopener');
    }
    async generateResultHref(context) {
        if (context?.product === 'eureka') {
            const { qid } = await CommonApis.queryItemToShort({
                q_s: context?.query_item,
            });
            return `${new Site({ sld: SLD.EUREKA }).href}/results/standard?playbook=${context?.playbook}&qid=${qid}`;
        }
        return '';
    }
    getCurrentLanguage() {
        return getCurrentLanguage();
    }
    getSuggestion(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/smart/suggest`,
            data,
        }).then((res) => {
            return res;
        });
    }
    // @ts-ignore
    async getInvitationService() {
        const { invitationServiceInstance } = await import('@eureka/invitation-reward/src/inviation.service');
        return invitationServiceInstance;
    }
    rendererFactory(intent) {
        const renderer = this._intentRendererRegistry.getRenderer({ intent });
        if (renderer) {
            return renderer;
        }
        return undefined;
    }
};
__decorate([
    inject(Message),
    __metadata("design:type", typeof (_a = typeof Message !== "undefined" && Message) === "function" ? _a : Object)
], AbstractEurekaCopilotService.prototype, "toast", void 0);
__decorate([
    inject(PatsnapCopilotIntentRendererRegistry),
    __metadata("design:type", typeof (_b = typeof PatsnapCopilotIntentRendererRegistry !== "undefined" && PatsnapCopilotIntentRendererRegistry) === "function" ? _b : Object)
], AbstractEurekaCopilotService.prototype, "_intentRendererRegistry", void 0);
__decorate([
    inject(PatsnapCopilotFeedBackService),
    __metadata("design:type", typeof (_c = typeof PatsnapCopilotFeedBackService !== "undefined" && PatsnapCopilotFeedBackService) === "function" ? _c : Object)
], AbstractEurekaCopilotService.prototype, "_feedbackService", void 0);
AbstractEurekaCopilotService = __decorate([
    injectable()
], AbstractEurekaCopilotService);
export { AbstractEurekaCopilotService };
