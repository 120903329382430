import { Site, SLD } from '@patsnap-utils/biz';
import { stringify } from 'qs';
/**
 *
 * @param id
 * @returns view link
 */
export function generatePatentViewHref(data) {
    if (!data.patentId)
        return '';
    const pathname = [
        `view/#/?patentId=${data.patentId}`,
        stringify(data?.params || {})
    ].join('&');
    return new Site({ sld: SLD.EUREKA, pathname }).href;
}
export function generatePaperViewHref(data) {
    if (!data.solutionId)
        return '';
    return new Site({ sld: SLD.EUREKA, pathname: `literature/#/?paperId=${data.solutionId}` }).href;
}
export const generateViewHref = generatePatentViewHref;
