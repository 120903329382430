import { CommonApis } from '@eureka/services/src/apis/common.api';
import { pwaService, trackParam } from '@eureka/utils/pwa';
export const pwaRouter = {
    path: 'pwa',
    name: 'pwa',
    beforeEnter: (to, from, next) => {
        if (pwaService.isInPwa) {
            CommonApis.track(trackParam('open'));
        }
        next({
            replace: true,
            path: 'home',
        });
    },
};
