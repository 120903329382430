import _ from 'lodash';
import { Playbooks } from '@eureka/services/src/types/playbook';
// 专利+专利语义
export const ALL_MATERIAL_PATENT_PLAYBOOKS = [Playbooks.MATERIAL, Playbooks.MATERIALNOVELTY];
// 文献+文献语义
export const ALL_MATERIAL_PAPER_PLAYBOOKS = [Playbooks.LITERATUREMATERIAL, Playbooks.LITERATUREMATERIALNOVELTY];
// 专利语义+文献语义
export const ALL_MATERIAL_NOVELTY_PLAYBOOKS = [Playbooks.MATERIALNOVELTY, Playbooks.LITERATUREMATERIALNOVELTY];
// 专利非语义+文献非语义
export const ALL_MATERIAL_COMMON_PLAYBOOKS = [Playbooks.MATERIALNOVELTY, Playbooks.LITERATUREMATERIALNOVELTY];
export const ALL_MATERIAL_PLAYBOOKS = [
    ...ALL_MATERIAL_PATENT_PLAYBOOKS,
    ...ALL_MATERIAL_PAPER_PLAYBOOKS
];
/**
 * 获取{ field:"MATERIAL" }结构下的property id list
 * @param items
 */
export const mapMaterialProperty = (items) => {
    const result = _.map(items, item => {
        return {
            pumList: _.filter(item.items, { field: 'PUM_PROP_ID' }),
        };
    });
    const propertyList = _.flattenDeep(_.map(result, item => {
        return _.map(item.pumList, pum => {
            return _.map(pum.items, 'value');
        });
    }));
    return propertyList;
};
// Srp PUM专利列表自定义排序
export const optimizePumSort = (pums, queryItems) => {
    let sortIds = [];
    _.forEach(queryItems, item => {
        // PUM
        if (item.field === 'MATERIAL') {
            const arr1 = mapMaterialProperty(item.items);
            sortIds = sortIds.concat(arr1);
        }
        // SPUM
        if (item.field === 'VF_SPUM') {
            const material = _.get(_.find(item.items, { field: 'MATERIAL' }), 'items', []);
            const arr2 = mapMaterialProperty(material);
            sortIds = sortIds.concat(arr2);
        }
    });
    const sortPums = _.map(pums, pum => {
        return {
            ...pum,
            sort: _.indexOf(sortIds, pum.property_id) === -1 || !pum?.unit ? 9999 : _.indexOf(sortIds, pum.property_id),
        };
    });
    const _sortPums = _.sortBy(sortPums, 'sort');
    return _sortPums;
};
