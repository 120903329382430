import { controlDeferred } from '@eureka/utils/control';
import { LocalStorageService } from './storage-service';
import { CommonApis } from './apis/common.api';
const cacheAirSelectedKey = 'user_selected_module';
export class UserSelectAirServiceImpl {
    setCacheSelected(module) {
        LocalStorageService.set(cacheAirSelectedKey, module);
    }
    getCacheSelected() {
        const selected = LocalStorageService.get(cacheAirSelectedKey);
        return selected ? selected : undefined;
    }
    clearCacheSelected() {
        LocalStorageService.delete(cacheAirSelectedKey);
    }
    track(detail) {
        CommonApis.track({
            event_type: 'SS Seek Search',
            event_detail_str: `switch-to-${detail.toLowerCase()}`,
            module_type: 'EUREKA',
            source_type: 'air',
        });
    }
    updateSelectedModule(module, track = true) {
        return CommonApis.setSelectedModule(module).then(() => {
            track && this.track(module);
            this.setCacheSelected(module);
            return module;
        });
    }
    /**
     * 用户可能在其他电脑上选择，如果选择过，会将值缓存到本地
     */
    async asyncGetSelected() {
        const { selected_module } = await controlDeferred.promise;
        const cacheSelected = this.getCacheSelected();
        if (!cacheSelected || cacheSelected !== selected_module) {
            this.setCacheSelected(selected_module);
        }
        return selected_module;
    }
}
export const userSelectAirService = new UserSelectAirServiceImpl();
