import { container } from '../container';
export class SmartApis {
    static getSearchSuggestApi(params) {
        const { fields, keyword, } = params;
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/smart/suggest`,
            data: {
                fields,
                keyword,
                n: 5,
            },
        }).then(res => {
            return res.data;
        });
    }
    static getPatentRecentHistory() {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/recent-history`,
        }).then(res => {
            return res.data;
        });
    }
    static previewSemantic(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/novelty-search-preview`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    static preview(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/preview`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    static previewCount(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/preview/count`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    static previewPatentOrPaper(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/preview`,
            data,
            cache: true,
        }).then(res => {
            return res.data;
        });
    }
    static clearImageRecentHistory() {
        return container.RnDHttp.delete({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/clear-image-recent-history`,
        }).then(res => {
            return res.data;
        });
    }
}
