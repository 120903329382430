import { EurekaProduct } from '@eureka/utils/control';
import { LocalStorageService } from './storage-service';
const SAVED_PRODUCT_KEY = 'SEARCH_SAVED_PRODUCT';
export class ProductCacheService {
    static save(prod) {
        LocalStorageService.set(SAVED_PRODUCT_KEY, prod);
    }
    static load() {
        return LocalStorageService.get(SAVED_PRODUCT_KEY) || EurekaProduct.GENERAL;
    }
    static clear() {
        return LocalStorageService.delete(SAVED_PRODUCT_KEY);
    }
}
