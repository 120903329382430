import _ from 'lodash';
export function format(value) {
    const _value = parseInt(`0${value}`);
    return Intl.NumberFormat('en-US').format(_value);
}
export const formatNumberValue = (number, emptyStr) => {
    if (!number)
        return emptyStr || '-';
    return number.toString().replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,');
};
export function formatNumberByKilo(number) {
    const temp = parseFloat(number);
    if (temp > 999999999) {
        return `${Math.round(temp / 10000000) / 100}B`;
    }
    if (temp > 99999999) {
        return `${Math.round(temp / 1000000)}M`;
    }
    if (temp > 9999999) {
        return `${Math.round(temp / 100000) / 10}M`;
    }
    if (temp > 999999) {
        return `${Math.round(temp / 10000) / 100}M`;
    }
    if (temp > 99999) {
        return `${Math.round(temp / 1000)}K`;
    }
    if (temp > 9999) {
        return `${Math.round(temp / 100) / 10}K`;
    }
    return String(number).replace(/\B(?=(\d{3})+$)/g, ',');
}
/**
* 格式化数值大小, 输出成带单位的字符串
* @param {Number} num 数值大小
* @param {Number} [digits=2] 精确到的小数点数。
* @param {Number} [toThousands=true] 千分位逗号分隔。
*/
export function formatNumberByKilo2(num, digits = 2, toThousands = true) {
    // 处理符号
    let sign = '';
    if (num < 0) {
        sign = '-';
        num = -num;
    }
    // 处理数据
    const si = [
        { value: 1, symbol: '' },
        { value: 1E3, symbol: 'K' },
        { value: 1E6, symbol: 'M' },
        { value: 1E9, symbol: 'B' },
        { value: 1E12, symbol: 'T' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    let n = (num / si[i].value).toFixed(digits).replace(rx, '$1');
    if (toThousands) {
        // 处理小数点
        let [left, right] = n.toString().split('.');
        left = left.replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,');
        n = _.compact([left, right]).join('.');
    }
    const u = si[i].symbol;
    return sign + n + u;
}
