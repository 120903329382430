import _ from 'lodash';
import { ApiResults } from '@eureka/services/src/apis/results.api';
import { formatNumberValue } from '@eureka/utils/number-format';
export var EnumVisualDisplayOptionDisplayContent;
(function (EnumVisualDisplayOptionDisplayContent) {
    /**
     * 显示 X 轴标题
     */
    EnumVisualDisplayOptionDisplayContent["XAxisTitle"] = "XAxisTitle";
    /**
     * 显示 Y 轴标题
     */
    EnumVisualDisplayOptionDisplayContent["YAxisTitle"] = "YAxisTitle";
    /**
     * 显示图例
     */
    EnumVisualDisplayOptionDisplayContent["Legend"] = "Legend";
    /**
     * 显示数值
     */
    EnumVisualDisplayOptionDisplayContent["ChartData"] = "ChartData";
    /**
     * 显示分类号描述
     */
    EnumVisualDisplayOptionDisplayContent["ClassificationDescription"] = "ClassificationDescription";
})(EnumVisualDisplayOptionDisplayContent || (EnumVisualDisplayOptionDisplayContent = {}));
const msSaveOrOpenBlob = 'msSaveOrOpenBlob' in window.navigator;
/**
 * 导出 png 图片
 * @param dataUrl
 * @param filename
 */
// @ts-ignore
export async function exportPNG(dataUrl, filename) {
    if (dataUrl instanceof Promise) {
        dataUrl = await dataUrl;
    }
    if (msSaveOrOpenBlob) {
        const bstr = atob(dataUrl.split(',')[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.png`);
    }
    else {
        const dlLink = document.createElement('a');
        dlLink.download = `${filename}.png`;
        dlLink.href = dataUrl;
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
    }
}
export async function exportExcel(visual, analysisSetting, fileName, customLabel = '') {
    const title = fileName ? `${new Date().getTime()}/${fileName}` : `${new Date().getTime()}`;
    let tableHeaders;
    let tableData;
    const size = analysisSetting.dimensionSettings.length;
    const measures = analysisSetting.measures;
    const baseRecords = visual.originRecords;
    const isShowDesc = visual.displayOptions.displayContent.value.includes(EnumVisualDisplayOptionDisplayContent.ClassificationDescription);
    const isCs = visual.isClassificationDimensions;
    function getName(data, dataIndex, isShowDesc, isClassification) {
        return isShowDesc ? visual.getCutawayName(data.code, dataIndex) : visual.getCutawayName(data.code, dataIndex, isClassification);
    }
    if (size === 1) {
        const records = visual.records || visual.allRecords;
        const isShowPercentage = visual.showPercentageValue;
        const aLabel = customLabel || analysisSetting?.dimensionSettings?.[0]?.dimension.name || '';
        tableHeaders = [aLabel, ..._.map(measures, measure => measure.name || '')];
        tableData = [];
        if (!['VisualTable'].includes(visual.code)) {
            tableData = records.map(item => [
                getName(item, 0, isShowDesc, isCs[0]),
                isShowPercentage ? `${item.value}%` : item.value
            ]);
        }
        else {
            records[0].forEach((item, index) => {
                const result = [];
                result.push(getName(item, 0, isShowDesc, isCs[0]));
                measures.forEach((measure, measureIndex) => {
                    const value = _.get(records, `[${measureIndex}][${index}].value`, 0);
                    result.push(isShowPercentage ? `${value}%` : value);
                });
                tableData.push(result);
            });
        }
    }
    else if (size === 2 && measures.length === 1) {
        const records = visual.sortedRecords || baseRecords;
        const firstItem = analysisSetting.dimensionSettings[0];
        const firstDesc = firstItem?.dimension?.name || '';
        const secondItem = analysisSetting.dimensionSettings[1];
        const secondDesc = secondItem?.dimension?.name || '';
        const secondList = visual.sortedCutaways ? visual.sortedCutaways[1] : visual.originCutaways[1];
        const headerMap = [];
        tableHeaders = [];
        tableHeaders.push(`${firstDesc}/${secondDesc}`);
        secondList.forEach(item => {
            tableHeaders.push(getName(item, 1, isShowDesc, isCs[1]));
            headerMap.push(item);
        });
        tableData = records.map(record => {
            const { value = {} } = record;
            const valueArr = [];
            headerMap.forEach((item) => {
                valueArr.push(value[item.code]);
            });
            return [
                getName(record, 0, isShowDesc, isCs[0]),
                ...valueArr
            ];
        });
    }
    else {
        return;
    }
    const data = {
        file_name: title,
        charts: [
            {
                head: tableHeaders,
                body: tableData,
                sheet_name: 'sheet1',
            }
        ],
    };
    const res = await ApiResults.exportExcel(data);
    if (typeof res === 'string') {
        window.open(res, '_blank', 'noopener');
    }
}
export function multiLineTooltipFormatter(params, unit = '') {
    const measuresName = [];
    let stooltip = `<b style="white-space: nowrap;">${params[0].name}</b><div>`;
    _.forEach(params, item => {
        const count = formatNumberValue(item.data.value || 0);
        const name = item.seriesName;
        const measureName = _.get(item, 'data.measure', '');
        if (measureName && !measuresName.includes(measureName)) {
            measuresName.push(measureName);
            stooltip += `<b style="padding-left:8px;color:#020A1A;width:100%;">${measureName}</b>`;
        }
        /* width:100%修复safari浏览器换行的情况 */
        if (measuresName.length === 0) {
            stooltip += `<div style="padding-left:16px;color:#020A1A;width:100%"><span style="position:absolute;margin-left: -16px;">${item.marker}</span>${name}: <span style="color:#495973;white-space: nowrap;">${unit} ${count}</span></div>`;
        }
        else {
            // 2个指标的情况
            stooltip += `<div style="padding-left:24px;color:#020A1A;width:100%"><span style="position:absolute;margin-left: -16px;">${item.marker}</span>${measuresName.length === 1 ? measuresName[0] : name}: <span style="color:#495973;white-space: nowrap;">${unit} ${count}</span></div>`;
        }
    });
    stooltip += '</div>';
    return `
        <div style="text-align:left; padding-right:16px;">
            ${stooltip}
        </div>
    `;
}
const DEFAULT_COLOR = '#061632';
export function createAxisLabelGraphic(axis) {
    const graphic = [];
    if (!axis)
        return graphic;
    const axisTitle = {
        type: 'group',
        left: axis.left,
        bottom: axis.bottom,
        name: axis.type,
        rotation: axis.rotation || 0,
        silent: true,
        children: [
            {
                type: 'rect',
                z: 100,
                left: 'center',
                top: 'middle',
            },
            {
                type: 'text',
                z: 100,
                left: 'center',
                top: 'middle',
                style: {
                    fill: axis.color || DEFAULT_COLOR,
                    text: axis.text,
                    fontSize: axis.fontSize,
                    fontFamily: axis.fontFamily,
                    fontWeight: axis.fontWeight,
                },
            }
        ],
    };
    graphic.push(axisTitle);
    return graphic;
}
