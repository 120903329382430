import { PlaybookQuery } from '@eureka/services/src/types/playbook';
import { COMMAND_CONFIG } from '@eureka/search-inputs/src/input-universal/locales/command-config';
import { get, find } from 'lodash';
const EUREKA_DESIGN_SEARCH_TYPE = PlaybookQuery.DESIGN;
export function getLocalDesignSearchType() {
    return localStorage.getItem(EUREKA_DESIGN_SEARCH_TYPE);
}
export function setLocalDesignSearchType(val) {
    return localStorage.setItem(EUREKA_DESIGN_SEARCH_TYPE, val);
}
/**
 * 获取命令的名称
 */
export function getCommandStr(key, lang = 'cn') {
    const currentLang = lang.toLowerCase();
    const item = find(COMMAND_CONFIG, { key });
    return get(item, `title.${currentLang}`) || key;
}
