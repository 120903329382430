import { __decorate, __metadata } from "tslib";
import _ from 'lodash';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getCurrentPageResultsInstance } from '@/presentation/pages/results/layout/page-results';
import { AbstractEurekaCopilotService } from '@eureka/services/src/eureka-copilot-service';
import { injectable } from '@patsnap/tahiti-core/shared/inversify';
import { headerService } from './header.service';
import { controlDeferred, EurekaProduct } from '@eureka/utils/control';
import { getAnalysisControllerInstance } from '@/presentation/components/results/analysis/analysis-controller';
import { searchInitDeferred } from './deferreds';
import Tools from '@eureka/copilot-plugins/src/components/Tools.vue';
import { messages } from '@eureka/copilot-plugins/src/locales/index';
let SrpCopilotServiceImpl = class SrpCopilotServiceImpl extends AbstractEurekaCopilotService {
    constructor() {
        super();
    }
    source = 'EUREKA_SRP';
    historyIdentifier() {
        return headerService.account._info.account_id;
    }
    actionCallback(type, data) { }
    pageParamFunc() {
        const instance = getCurrentPageResultsInstance();
        return {
            ...instance.parameters,
            doc: instance.doc,
            doc_s: instance.doc_s,
            patent_ids: _.map(instance.records, 'SOLUTION_ID'),
            total: instance.total,
        };
    }
    async getAnalysisChartStatus(context) {
        if (!context)
            return false;
        // 分析上限判断
        const pageInitData = await searchInitDeferred.promise;
        const limit = pageInitData.acl.analysis_count;
        const params = this.pageParamFunc();
        if (params.total > limit) {
            return false;
        }
        // 图标权限判断
        const analysisIns = getAnalysisControllerInstance();
        const chart = analysisIns.analysisCharts.find(chart => chart.chartType === context.analysis_type);
        return !chart.disabled;
    }
    contextValidator(context) {
        if (context.source !== this.source) {
            return false;
        }
        const pageParams = this.pageParamFunc();
        if (JSON.stringify(pageParams) !== JSON.stringify(context.params)) {
            return false;
        }
        return true;
    }
    async welcomeMessages() {
        const generalMsgs = [{
                title: {},
                prompt: {
                    cn: '总结一下当前页面',
                    en: 'Summarize the current page for me.',
                    jp: '現在のページを要約',
                    tw: '總結一下當前頁面',
                },
                intent: 'summary',
            }, {
                title: {},
                prompt: {
                    cn: '这些专利都达到了哪些技术效果？',
                    en: 'What technical effects have these patents achieved?',
                    jp: 'これらの特許はどの技術効果を達成しましたか？',
                    tw: '這些專利都達到了哪些技術效果？',
                },
                intent: 'analysis',
            }, {
                title: {},
                prompt: {
                    cn: '这些专利应用在哪些领域？',
                    en: 'In which fields are these patents applied?',
                    jp: 'これらの特許はどの分野で応用されていますか？',
                    tw: '這些專利應用在哪些領域？',
                },
                intent: 'analysis',
            }, {
                title: {},
                prompt: {
                    cn: '申请量最多的公司是哪些？',
                    en: 'Which companies have the highest number of applications?',
                    jp: '最も多くの特許出願を行っている企業はどれですか？',
                    tw: '申請量最多的公司是哪些？',
                },
                intent: 'company',
            }];
        const materialMsgs = [{
                title: {},
                prompt: {
                    cn: '提到的材料应用领域有哪些？',
                    en: 'What are the application fields mentioned for the materials?',
                    jp: '言及された材料の応用分野は何ですか？',
                    tw: '提到的材料應用領域有哪些？',
                },
                intent: 'analysis',
            }, {
                title: {},
                prompt: {
                    cn: '材料特性的分布和趋势是什么？',
                    en: 'What is the distribution and trend of the material properties?',
                    jp: '材料特性の分布と傾向は何ですか？',
                    tw: '材料特性的分布和趨勢是什麽？',
                },
                intent: 'analysis',
            }, {
                title: {},
                prompt: {
                    cn: '这些材料是如何制备的？',
                    en: 'How are these materials prepared?',
                    jp: 'これらの材料はどのように製造されていますか？',
                    tw: '這些材料是如何製備的？',
                },
                intent: 'analysis',
            }];
        const { product } = await controlDeferred.promise;
        let allMsgs = generalMsgs;
        if (product === EurekaProduct.MATERIAL) {
            allMsgs = [...generalMsgs, ...materialMsgs];
        }
        const lang = this.getCurrentLanguage();
        const suggestions = allMsgs.map(({ title, prompt, intent }) => {
            return {
                title: title[lang],
                prompt: prompt[lang],
                intent: intent,
            };
        });
        return Promise.resolve(suggestions);
    }
    getSocketType() {
        return 'COPILOT_SRP';
    }
    getAdvices() {
        const lang = this.getCurrentLanguage();
        const advices = {
            cn: [{
                    title: '创新发散助手',
                    desc: '利用Triz和第一性原理，与你并肩探索，开启无限的创新之旅，点燃思维火花。',
                }, {
                    title: '论文深度解读',
                    desc: '通过高级AI技术，精炼翻译，提取论文核心精华，简化你的研究过程。',
                }, {
                    title: 'AI可行性报告分析',
                    desc: '使用AI快速评估技术方向，提供全面分析报告，包含趋势、主要玩家与案例，定制化编辑功能，助你决策。',
                }, {
                    title: 'AI问题深度分析',
                    desc: '精确识别问题根源，提供设备、功能及工艺优化方案，以AI的力量高效实现系统完善。',
                }, {
                    title: '专业实验设计助手',
                    desc: '借助领域专业的AI大模型，量身定制实验目标、指标及方案，护航研究成功率。',
                }, {
                    title: '随身AI助手小程序',
                    desc: '无需PC，直接在小程序体验全能AI助手的强大功能，让工作随时随地轻松进行。',
                }, {
                    title: 'Eureka快速上手',
                    desc: '通过智能AI助手，提供个性化的快速入门指导，让你轻松掌握强大的Eureka。',
                }, {
                    title: '终极技术竞争分析',
                    desc: '只需简单输入，AI助手即可深度分析竞争对手的技术布局，为你揭示行业秘密。',
                }, {
                    title: '创意结构草图制作',
                    desc: '告诉我们你的想法，AI将为你呈现出具体的结构设计草图，帮助你将创意变为现实。',
                }, {
                    title: '创新亮点整理工具',
                    desc: 'AI智能解读，将新专利和论文的创新点聚焦一处，让每一次探索都充满价值。',
                }],
            en: [{
                    title: 'Innovation Exploration Buddy',
                    desc: 'Join hands with Triz and first principles to embark on an endless journey of innovation, sparking the light of creativity.',
                }, {
                    title: 'Deep Dive Paper Reader',
                    desc: 'With advanced AI technology, refine translations and extract the essence of papers, simplifying your research adventure.',
                }, {
                    title: 'AI Feasibility Report Analyst',
                    desc: 'Rapidly assess technology directions with AI, providing comprehensive reports including trends, key players, and cases, with customizable editing to aid your decision-making.',
                }, {
                    title: 'AI Problem Solver',
                    desc: 'Precisely identify the root of problems, offering optimization solutions for devices, functions, and processes with the power of AI, perfecting systems efficiently.',
                }, {
                    title: 'Professional Experiment Planner',
                    desc: 'With the help of specialized AI models, tailor-made experiment goals, metrics, and plans are crafted to ensure your research success.',
                }, {
                    title: 'Portable AI Assistant App',
                    desc: 'No need for a PC, dive into the powerful features of the all-encompassing AI assistant via a mini-app, making work easy anytime, anywhere.',
                }, {
                    title: 'Eureka Quick Learner',
                    desc: 'Get personalized, quick-start guidance from our smart AI assistant to easily master the powerful Eureka toolkit.',
                }, {
                    title: 'Ultimate Tech Rival Analyzer',
                    desc: 'Just a simple input and our AI assistant dives deep into analyzing your competitors\' tech landscape, unveiling industry secrets for you.',
                }, {
                    title: 'Creative Structure Sketch Maker',
                    desc: 'Share your ideas with us, and AI will present you with detailed structural design sketches, helping turn your creativity into reality.',
                }, {
                    title: 'Innovation Highlight Organizer',
                    desc: 'With intelligent AI interpretation, group new patents and papers\' innovative points together, making each exploration journey valuable.',
                }],
            jp: [{
                    title: 'Innovation Exploration Buddy',
                    desc: 'Join hands with Triz and first principles to embark on an endless journey of innovation, sparking the light of creativity.',
                }, {
                    title: 'Deep Dive Paper Reader',
                    desc: 'With advanced AI technology, refine translations and extract the essence of papers, simplifying your research adventure.',
                }, {
                    title: 'AI Feasibility Report Analyst',
                    desc: 'Rapidly assess technology directions with AI, providing comprehensive reports including trends, key players, and cases, with customizable editing to aid your decision-making.',
                }, {
                    title: 'AI Problem Solver',
                    desc: 'Precisely identify the root of problems, offering optimization solutions for devices, functions, and processes with the power of AI, perfecting systems efficiently.',
                }, {
                    title: 'Professional Experiment Planner',
                    desc: 'With the help of specialized AI models, tailor-made experiment goals, metrics, and plans are crafted to ensure your research success.',
                }, {
                    title: 'Portable AI Assistant App',
                    desc: 'No need for a PC, dive into the powerful features of the all-encompassing AI assistant via a mini-app, making work easy anytime, anywhere.',
                }, {
                    title: 'Eureka Quick Learner',
                    desc: 'Get personalized, quick-start guidance from our smart AI assistant to easily master the powerful Eureka toolkit.',
                }, {
                    title: 'Ultimate Tech Rival Analyzer',
                    desc: 'Just a simple input and our AI assistant dives deep into analyzing your competitors\' tech landscape, unveiling industry secrets for you.',
                }, {
                    title: 'Creative Structure Sketch Maker',
                    desc: 'Share your ideas with us, and AI will present you with detailed structural design sketches, helping turn your creativity into reality.',
                }, {
                    title: 'Innovation Highlight Organizer',
                    desc: 'With intelligent AI interpretation, group new patents and papers\' innovative points together, making each exploration journey valuable.',
                }],
            tw: [{
                    title: '創新發散助手',
                    desc: '利用Triz和第一性原理，與你並肩探索，開啟無限的創新之旅，點燃思維火花。',
                }, {
                    title: '論文深度解讀',
                    desc: '通過高級AI技術，精煉翻譯，提取論文核心精華，簡化你的研究過程。',
                }, {
                    title: 'AI可行性報告分析',
                    desc: '使用AI快速評估技術方向，提供全面分析報告，包含趨勢、主要玩家與案例，定製化編輯功能，助你決策。',
                }, {
                    title: 'AI問題深度分析',
                    desc: '精確識別問題根源，提供設備、功能及工藝優化方案，以AI的力量高效實現系統完善。',
                }, {
                    title: '專業實驗設計助手',
                    desc: '借助領域專業的AI大模型，量身定製實驗目標、指標及方案，護航研究成功率。',
                }, {
                    title: '隨身AI助手小程序',
                    desc: '無需PC，直接在小程序體驗全能AI助手的強大功能，讓工作隨時隨地輕松進行。',
                }, {
                    title: 'Eureka快速上手',
                    desc: '通過智能AI助手，提供個性化的快速入門指導，讓你輕松掌握強大的Eureka。',
                }, {
                    title: '終極技術競爭分析',
                    desc: '只需簡單輸入，AI助手即可深度分析競爭對手的技術布局，為你揭示行業秘密。',
                }, {
                    title: '創意結構草圖製作',
                    desc: '告訴我們你的想法，AI將為你呈現出具體的結構設計草圖，幫助你將創意變為現實。',
                }, {
                    title: '創新亮點整理工具',
                    desc: 'AI智能解讀，將新專利和論文的創新點聚焦一處，讓每一次探索都充滿價值。',
                }],
        };
        return advices[lang];
    }
    menuList() {
        const lang = this.getCurrentLanguage();
        return [{
                builtInContent: 'chat',
            }, {
                iconHTML: '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" fill="white" fill-opacity="0.01"/><path d="M8 12V13.5C8 13.6326 8.05268 13.7598 8.14645 13.8536C8.24021 13.9473 8.36739 14 8.5 14C8.63261 14 8.75979 13.9473 8.85355 13.8536C8.94732 13.7598 9 13.6326 9 13.5V12H15V13.5C15 13.617 15.041 13.7304 15.1159 13.8203C15.1908 13.9102 15.2949 13.9709 15.41 13.992L15.5 14C15.6326 14 15.7598 13.9473 15.8536 13.8536C15.9473 13.7598 16 13.6326 16 13.5V12H21V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V12H8ZM15 4C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5V7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V11H16V9.5C16 9.38297 15.959 9.26964 15.8841 9.17974C15.8092 9.08984 15.7051 9.02907 15.59 9.008L15.5 9C15.3674 9 15.2402 9.05268 15.1464 9.14645C15.0527 9.24021 15 9.36739 15 9.5V11H9V9.5C9.00004 9.38297 8.95903 9.26964 8.88411 9.17974C8.80919 9.08984 8.70511 9.02907 8.59 9.008L8.5 9C8.36739 9 8.24021 9.05268 8.14645 9.14645C8.05268 9.24021 8 9.36739 8 9.5V11H3V9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7H8V5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H15Z" /></svg>',
                title: messages[lang].title,
                key: 'tools',
                content: () => {
                    const div = document.createElement('div');
                    const i18n = new VueI18n({
                        locale: lang,
                    });
                    const app = new Vue({
                        i18n,
                        render: h => h(Tools, {
                            props: {
                                service: this,
                            },
                        }),
                    }).$mount(div);
                    return {
                        renderer: () => {
                            return app.$el;
                        },
                        dispose: () => {
                            app.$destroy();
                        },
                    };
                },
            }, {
                builtInContent: 'advice',
            }];
    }
};
SrpCopilotServiceImpl = __decorate([
    injectable(),
    __metadata("design:paramtypes", [])
], SrpCopilotServiceImpl);
export { SrpCopilotServiceImpl };
