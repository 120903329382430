/**
 * 搜索结果页模式
 */
export var EResultsPattern;
(function (EResultsPattern) {
    /**
     * 专利结果页
     */
    EResultsPattern["DEFAULT"] = "DEFAULT";
    /**
     * 文献结果页
     */
    EResultsPattern["PAPER"] = "PAPER";
    /**
     * 图像-外观结果页
     */
    EResultsPattern["DESIGN"] = "DESIGN";
    /**
     * 图像-实用新型结果页
     */
    EResultsPattern["UTILITY"] = "UTILITY";
    /**
     * 分析结果页
     */
    EResultsPattern["ANALYSIS"] = "ANALYSIS";
    /**
     * 材料结果页
     */
    EResultsPattern["MATERIAL"] = "MATERIAL";
    /**
     * 材料文献页
     */
    EResultsPattern["MATERIAL_PAPER"] = "MATERIAL_PAPER";
})(EResultsPattern || (EResultsPattern = {}));
/**
  * 语言类型
  */
export var ELang;
(function (ELang) {
    ELang["CN"] = "CN";
    ELang["EN"] = "EN";
    ELang["TW"] = "TW";
    ELang["JP"] = "JP";
    ELang["NONE"] = "NONE";
})(ELang || (ELang = {}));
