import _ from 'lodash';
import { SmartApis } from './apis/smart.api';
import { E_QueryLogic, E_QueryType, E_QueryField } from './types/query';
export const UNSAFE_FIELDS = ['KEYWORD'];
export function blockName(field, i18n) {
    if (i18n) {
        const t = i18n.t.bind(i18n) || i18n.global.t.bind(i18n.global);
        return t(`queryBlockField.${field}`);
    }
    return field;
}
// 有items的query节点，比如range是没有的
export const typesWithItems = [
    E_QueryType.BOOL,
    E_QueryType.KEYWORD,
    E_QueryType.FIELD
];
// 排他字段，如果出现在query中，query将无法编辑
export const EXCLUSIVE_FIELDS = ['VF_COPILOT_CHAT_PATENT', 'VF_COPILOT_CHAT_PAPER', 'VF_NEWS_LETTER'];
export const STRUCTURE_BLOCKS = [E_QueryField.VF_SUBSTANCE_EXT, E_QueryField.VF_SUBSTANCE_SIM, E_QueryField.VF_SUBSTANCE_SUB, E_QueryField.VF_SUBSTANCE_SUP, E_QueryField.VF_SUBSTANCE_STRUCTURE];
const WS_FIELD_ID = 'CWS_IDS';
// 只有一个block
function isPlainQuery(q) {
    if (!q)
        return true;
    return q.items.length === 1;
}
export function isPumBlock(item) {
    return item.type === E_QueryType.FIELD && item.field === 'MATERIAL';
}
export function isSpumBlock(item) {
    return item.type === E_QueryType.FIELD && item.field === 'VF_SPUM';
}
export function isSubstanceFilterBlock(item) {
    return item.type === E_QueryType.FIELD && _.includes([E_QueryField.SUBSTANCE_ALL, E_QueryField.SUBSTANCE_ONLY], item.field);
}
export function isSubstancePoolBlock(item) {
    return item.type === E_QueryType.FIELD && item.field === E_QueryField.VF_SFP;
}
export function isStructureBlock(item) {
    return item.type === E_QueryType.FIELD &&
        _.includes(STRUCTURE_BLOCKS, item.field);
}
export function getSubstancePoolFormattedText(item, index, value, i18n) {
    let str = `${blockName(item.field, i18n)}: ${item.name}`;
    if (index + 1 !== _.size(value.items)) {
        str += ';';
    }
    return str;
}
export function getStructureFormattedText(item, value, i18n) {
    if (value.field === E_QueryField.VF_SUBSTANCE_STRUCTURE) {
        return item.name;
    }
    const typeName = i18n.t(`specialQueryField.${value.field}`);
    return item.name !== item.value ? `${typeName} ${item.name}` : typeName;
}
// 剥去前端只有关键字block下生成的多余括号
export function queryOptimize(q) {
    if (!q)
        return q;
    if (isPlainQuery(q) && q.items[0].type === E_QueryType.BOOL) {
        return q.items[0];
    }
    return q;
}
export function blocks2Query(blocks) {
    const mapPumItem = (items) => {
        return _.map(items, (item, index) => {
            const pum = {
                logic: index === 0 ? E_QueryLogic.AND : item.logic,
                type: E_QueryType.BOOL,
                items: [{
                        logic: E_QueryLogic.AND,
                        type: E_QueryType.FIELD,
                        field: 'PUM_PROP_ID',
                        items: [{
                                name: item.name,
                                logic: E_QueryLogic.AND,
                                type: E_QueryType.KEYWORD,
                                value: item.value,
                            }],
                    }],
            };
            if (item.u) {
                pum.items?.push({
                    logic: E_QueryLogic.AND,
                    type: E_QueryType.FIELD,
                    field: 'PUM_UNIT',
                    items: [{
                            name: '',
                            logic: E_QueryLogic.AND,
                            type: E_QueryType.KEYWORD,
                            value: item.u,
                        }],
                });
            }
            if (_.isArray(item.m)) {
                pum.items?.push({
                    logic: E_QueryLogic.AND,
                    type: E_QueryType.RANGE,
                    field: 'PUM_VALUE',
                    from: item.m[0],
                    to: item.m[1],
                });
            }
            return pum;
        });
    };
    const items = _.map(blocks, (block) => {
        if (_.includes(typesWithItems, block.type) && !block.items?.length)
            return null;
        if (block.field === 'KEYWORD') {
            // // 精确搜索
            // // 关键字全塞到bool中括起来
            // return _.omit({
            //     ...block,
            //     type: E_QueryType.BOOL,
            //     items: _.map(block.items, (item, index) => ({ ..._.omit(item, ['name', 'field']), type: E_QueryType.KEYWORD, logic: index === 0 ? E_QueryLogic.AND : item.logic, })),
            // }, ['field']);
            // 转成语义
            return {
                ...block,
                field: 'TEXT',
                items: _.map(block.items, (item, index) => _.omit({
                    ...item,
                    type: E_QueryType.KEYWORD,
                    logic: index === 0 ? E_QueryLogic.AND : item.logic,
                }, ['field'])),
            };
        }
        else if (block.field === 'MATERIAL') {
            return {
                ...block,
                items: mapPumItem(block.items),
            };
        }
        else if (block.field === 'VF_SPUM') {
            //! do spum block to query logic
            return {
                ...block,
                items: _.map(block.items, (item) => {
                    if (isPumBlock(item)) {
                        return {
                            ...item,
                            items: mapPumItem(item.items),
                        };
                    }
                    return item;
                }),
            };
        }
        else if (block.field === E_QueryField.VF_SFP) {
            return {
                ...block,
                items: _.map(block.items, (item) => _.omit(item, ['name'])),
            };
        }
        else {
            if (block.type === E_QueryType.RANGE)
                return block;
            return {
                ...block,
                items: _.map(block.items, (item, index) => _.omit({
                    ...item,
                    type: E_QueryType.KEYWORD,
                    logic: index === 0 ? E_QueryLogic.AND : item.logic,
                }, ['field'])),
            };
        }
    });
    const compactedItems = _.compact(items);
    return queryOptimize({
        logic: E_QueryLogic.AND,
        type: E_QueryType.BOOL,
        items: compactedItems,
    });
}
export function pumBlockItemToPumItem(item) {
    const pumBlock = queryOptimize(item);
    let pumItem = null;
    if (pumBlock.type === E_QueryType.BOOL) {
        // p u m
        const p = _.get(_.find(pumBlock.items, { field: 'PUM_PROP_ID' }), 'items.0', {});
        const u = _.get(_.find(pumBlock.items, { field: 'PUM_UNIT' }), 'items.0', {});
        const m = _.find(pumBlock.items, { field: 'PUM_VALUE' });
        pumItem = {
            logic: pumBlock.logic,
            name: p?.name || '',
            value: p?.value || '',
            u: u?.value || '',
            m: m ? [m?.from, m?.to] : undefined,
        };
    }
    else if (pumBlock.type === E_QueryType.FIELD && pumBlock.items.length === 1 && pumBlock.items[0].field === 'PUM_PROP_ID') {
        // 只有1个p
        pumItem = {
            logic: pumBlock.logic,
            // @ts-ignore
            name: pumBlock.items[0].name,
            value: pumBlock.items[0].value,
        };
    }
    else {
        console.warn('Pum unsupported');
    }
    // @ts-ignore
    return pumItem;
}
export function pumBlockItemsToPumItems(items) {
    return _.map(items, item => pumBlockItemToPumItem(item));
}
export function separateBlocks(blocks) {
    return _.partition(blocks, block => {
        return block.type === E_QueryType.FIELD && block.field === WS_FIELD_ID;
    });
}
export function extractWsInQuery(query) {
    const [ws] = separateBlocks(query?.items || []);
    return ws?.[0]?.items || [];
}
export function query2Blocks(query, ignoreWs = true) {
    if (!query)
        return [];
    if (query.logic !== E_QueryLogic.AND) {
        throw new Error('Invalid query logic for UI');
    }
    if (query.type !== E_QueryType.BOOL) {
        throw new Error('Invalid query type for UI');
    }
    // // 精确关键字搜索
    // // 扁平的keyword，来自后端转换
    // if (_.every(query.items, subItem => subItem.type === E_QueryType.KEYWORD)) {
    //     return [{
    //         logic: E_QueryLogic.AND,
    //         type: E_QueryType.FIELD,
    //         field: 'KEYWORD',
    //         items: _.map(query.items, (subItem: IQueryBlock) => {
    //             return {
    //                 ...subItem,
    //                 name: subItem.value,
    //             }
    //         }),
    //     }]
    // }
    // 根据参数判断是否删除query中的ws范围
    const [, normalBlocks] = ignoreWs ? separateBlocks(query.items) : [null, query.items];
    // @ts-ignore
    return _.map(normalBlocks, (item) => {
        if (isPumBlock(item)) {
            return {
                ...item,
                items: pumBlockItemsToPumItems(item.items),
            };
        }
        if (isSubstancePoolBlock(item)) {
            return {
                ...item,
                items: _.map(item.items, (subItem) => {
                    return {
                        ...subItem,
                        name: _.map(subItem.items, (ssItem) => ssItem.name).join(','),
                    };
                }),
            };
        }
        // 前端拼的keyword会包在bool内
        if (item.type === E_QueryType.BOOL && _.every(item.items, subItem => subItem.type === E_QueryType.KEYWORD)) {
            // 括号里的关键字转成keyword的block
            return {
                ...item,
                type: E_QueryType.FIELD,
                field: 'KEYWORD',
                items: _.map(item.items, (subItem) => {
                    return {
                        ...subItem,
                        name: subItem.name || subItem.value,
                    };
                }),
            };
        }
        // 替换上面注释掉的精确搜索，兼容泛域语义
        if (item.field === 'TEXT') {
            item = {
                ...item,
                field: 'KEYWORD',
            };
        }
        // !处理SPUM 的逻辑
        if (item.field === 'VF_SPUM') {
            return {
                ...item,
                items: _.map(item.items, (subItem) => {
                    return {
                        ...subItem,
                        items: isPumBlock(subItem) ? pumBlockItemsToPumItems(subItem.items) : subItem.items,
                    };
                }),
            };
        }
        return {
            ...item,
            items: _.map(item.items, (subItem) => {
                return {
                    ...subItem,
                    name: subItem.name || subItem.value,
                };
            }),
        };
    });
}
export function isQueryEmpty(query) {
    if (!query)
        return true;
    return _.isEmpty(query.items);
}
export function isSpaceQueryEmpty(query) {
    const [, normalBlocks] = separateBlocks(query.items);
    return normalBlocks.length === 0;
}
export function isQueryBlockWithOnlySpace(queryBlocks) {
    const [wsBlocks, normalBlocks] = separateBlocks(queryBlocks);
    return normalBlocks.length === 0 && wsBlocks?.[0]?.items?.length > 0;
}
export function isQueryWithOnlySpace(query) {
    return isQueryBlockWithOnlySpace(query2Blocks(query, false));
}
export function pathOfQueryBlockWithOnlySpace(queryBlocks) {
    const [wsBlocks, normalBlocks] = separateBlocks(queryBlocks);
    if (normalBlocks.length > 0 && wsBlocks?.[0]?.items?.length === 0)
        return '';
    return wsBlocks?.[0]?.items?.map((item, idx) => {
        return idx === 0 ? `${item.name}` : `${item.logic} ${item.name}`;
    }).join(' ');
}
export function pathOfQueryWithOnlySpace(query) {
    return pathOfQueryBlockWithOnlySpace(query2Blocks(query, false));
}
export function isSimpleQuery(q) {
    if (!q)
        return true;
    if (isPlainQuery(q)) {
        const subItem = q.items[0];
        if (subItem.type === 'KEYWORD') {
            return true;
        }
        if (subItem.type === E_QueryType.FIELD && subItem.field === 'TEXT' && subItem?.items?.length === 1) {
            return true;
        }
    }
    return false;
}
// 将query结构转为泛域的tag，仅支持单block单值的query
export function query2tag(q) {
    q = queryOptimize(q);
    if (isPlainQuery(q)) {
        const subItem = q.items[0];
        if (subItem.type === 'KEYWORD') {
            return {
                type: '',
                value: subItem.value,
            };
        }
        else if (subItem?.items?.length === 1) {
            const keywordItem = subItem.items[0];
            const field = subItem.field || '';
            if (field === 'TEXT') {
                return {
                    type: '',
                    value: keywordItem.value,
                };
            }
            return {
                type: field,
                value: keywordItem?.value,
            };
        }
    }
    console.warn('Query is not supported');
    return { type: '', value: '' };
}
// 通过字段&值生成一个最简单query结构
export function generateSimpleQuery(field, value, id, name) {
    return blocks2Query([{
            logic: E_QueryLogic.AND,
            type: E_QueryType.FIELD,
            field,
            items: [{
                    logic: E_QueryLogic.AND,
                    type: E_QueryType.KEYWORD,
                    value,
                    id,
                    name,
                }],
        }]);
}
// 用于在结构化query转换成用于搜索匹配的字符串
export function query2SearchStr(q, i18n) {
    const blocks = query2Blocks(q);
    const searchStr = _.map(blocks, (block, idx) => {
        const keywordsStr = _.map(block.items, (item, idx) => {
            const logic = (idx === 0 ? '' : `${item.logic}`);
            return _.trim(`${logic} ${item.name}`);
        }).join(' ');
        const logic = (idx === 0 ? '' : `${block.logic}`);
        return `${logic} ${blockName(block.field, i18n)} ${keywordsStr}`;
    }).join(' ');
    return searchStr;
}
export class QueryItem {
    name;
    logic;
    constructor(logic, name) {
        this.name = name;
        this.logic = logic;
    }
    getData() {
        return {
            logic: this.logic,
            type: E_QueryType.KEYWORD,
            name: this.name,
        };
    }
}
export class KeywordQueryItem extends QueryItem {
    value;
    constructor(logic, name, value) {
        super(logic, name);
        this.value = value;
    }
    getData() {
        return {
            ...super.getData(),
            value: this.value,
        };
    }
}
export class QueryBlock {
    type = E_QueryType.KEYWORD;
    logic = E_QueryLogic.AND;
    items = [];
    getData() {
        return {
            logic: this.logic,
            type: this.type,
            items: _.map(this.items, (item) => {
                return item.getData();
            }),
        };
    }
}
export class FieldQueryBlock extends QueryBlock {
    field;
    constructor(field) {
        super();
        this.field = field;
        this.type = E_QueryType.FIELD;
    }
    addItem(item) {
        this.items.push(item);
    }
    getData() {
        return {
            ...super.getData(),
            field: this.field,
        };
    }
}
export function hasExclusiveBlock(blocks) {
    return _.some(blocks, block => EXCLUSIVE_FIELDS.includes(block.field || ''));
}
// 单字段高级搜索重定向到结果页
export async function query2ResultParams(playbook, field, value, id, name) {
    if (!field || !value || !id) {
        return;
    }
    const params = {
        playbook,
        q_s: generateSimpleQuery(field, value, id, name),
    };
    return SmartApis.preview(params);
}
