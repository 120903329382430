export var E_QueryLogic;
(function (E_QueryLogic) {
    E_QueryLogic["AND"] = "AND";
    E_QueryLogic["OR"] = "OR";
})(E_QueryLogic || (E_QueryLogic = {}));
export var E_QueryType;
(function (E_QueryType) {
    E_QueryType["BOOL"] = "BOOL";
    E_QueryType["KEYWORD"] = "KEYWORD";
    E_QueryType["FIELD"] = "FIELD";
    E_QueryType["RANGE"] = "RANGE";
})(E_QueryType || (E_QueryType = {}));
export var E_QueryField;
(function (E_QueryField) {
    E_QueryField["TEXT"] = "TEXT";
    E_QueryField["KEYWORD"] = "KEYWORD";
    /**
     * MATERIAL 相关
     */
    E_QueryField["VF_SPUM"] = "VF_SPUM";
    E_QueryField["VF_MATERIAL_CLASSIFICATION"] = "VF_MATERIAL_CLASSIFICATION";
    E_QueryField["PUM_PROP_ID"] = "PUM_PROP_ID";
    E_QueryField["PUM_UNIT"] = "PUM_UNIT";
    E_QueryField["SUBSTANCE_ALL"] = "SUBSTANCE_ALL";
    E_QueryField["SUBSTANCE_ONLY"] = "SUBSTANCE_ONLY";
    E_QueryField["SUBSTANCE_IDS"] = "SUBSTANCE_IDS";
    /**
     * 配方池字段
     */
    E_QueryField["VF_SFP"] = "VF_SFP";
    E_QueryField["VF_MUST"] = "VF_MUST";
    E_QueryField["VF_ANY"] = "VF_ANY";
    E_QueryField["VF_NOT"] = "VF_NOT";
    /**
     * 结构式字段
     */
    E_QueryField["VF_SUBSTANCE_EXT"] = "VF_SUBSTANCE_EXT";
    E_QueryField["VF_SUBSTANCE_SIM"] = "VF_SUBSTANCE_SIM";
    E_QueryField["VF_SUBSTANCE_SUB"] = "VF_SUBSTANCE_SUB";
    E_QueryField["VF_SUBSTANCE_SUP"] = "VF_SUBSTANCE_SUP";
    E_QueryField["VF_SUBSTANCE_STRUCTURE"] = "VF_SUBSTANCE_STRUCTURE";
})(E_QueryField || (E_QueryField = {}));
