export var Playbooks;
(function (Playbooks) {
    Playbooks["SMART"] = "SmartSearch";
    Playbooks["NOVELTY"] = "NoveltySearch";
    Playbooks["DESIGN"] = "DesignSearch";
    Playbooks["LITERATURE"] = "LiteratureSearch";
    Playbooks["LITERATURENOVELTY"] = "LiteratureNoveltySearch";
    Playbooks["MATERIAL"] = "MaterialSearch";
    Playbooks["LITERATUREMATERIAL"] = "LiteratureMaterialSearch";
    Playbooks["MATERIALNOVELTY"] = "MaterialNoveltySearch";
    Playbooks["LITERATUREMATERIALNOVELTY"] = "LiteratureMaterialNoveltySearch";
})(Playbooks || (Playbooks = {}));
export var PlaybookQuery;
(function (PlaybookQuery) {
    PlaybookQuery["SMART"] = "query";
    PlaybookQuery["NOVELTY"] = "novelty";
    PlaybookQuery["WS"] = "workspace";
    PlaybookQuery["DESIGN"] = "design";
    PlaybookQuery["UTILITY"] = "utility";
})(PlaybookQuery || (PlaybookQuery = {}));
