import { HttpSuccessException } from './exception';
export function isErrorCode(err, codes = []) {
    if (!HttpSuccessException.is(err)) {
        return false;
    }
    return codes.includes(err.data?.error_code);
}
/**
 * 需要自己抓错误码进行处理的地方
 */
export function hookErrorCode(p, codes, fn) {
    return p.catch(error => {
        if (isErrorCode(error, codes)) {
            fn(error);
        }
        else {
            throw error;
        }
    });
}
