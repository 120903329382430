import _ from 'lodash';
import { EurekaProduct, controlDeferred } from '@eureka/utils/control';
import { getCurrentPageResultsInstance } from '@/presentation/pages/results/layout/page-results';
import { queryLimitation } from '@/services/limitation.service';
import { ApiResults } from '@eureka/services/src/apis/results.api';
import { E_ANALYSIS_CHART, generalCharts, materialCharts } from './charts';
import { getChartOption } from './chart-option';
import { EBenefitTab } from '@/types/result';
export const hasMaterialPermission = async () => {
    const { product } = await controlDeferred.promise;
    return product === EurekaProduct.MATERIAL;
};
export var E_CHART_STATUS;
(function (E_CHART_STATUS) {
    E_CHART_STATUS["LOADING"] = "loading";
    E_CHART_STATUS["DISABLED"] = "disabled";
})(E_CHART_STATUS || (E_CHART_STATUS = {}));
export var E_CHART_SETTING;
(function (E_CHART_SETTING) {
    E_CHART_SETTING["VISUAL"] = "visual";
    E_CHART_SETTING["ANALYSIS_SETTING"] = "analysisSetting";
})(E_CHART_SETTING || (E_CHART_SETTING = {}));
export class AnalysisController {
    hasMaterialPermission = false;
    resultsInstance = getCurrentPageResultsInstance();
    limitations = {};
    constructor() {
        this.init();
    }
    /**
     * spum搜索模式: s + p + u + m（至少有一个范围值）
     */
    get isSpumSearchModel() {
        return !this.limitations?.analysisChartSPUM;
    }
    /**
     * 处理图表展示
     * 1. 有材料权限
     * 2. 有材料&spum搜索模式
     */
    get analysisCharts() {
        let general = generalCharts;
        if (this.limitations.analysisChart) {
            general = _.map(generalCharts, (chart, index) => {
                return {
                    ...chart,
                    disabled: index >= 2,
                    disabledReason: index >= 2 ? 'freemium' : '',
                };
            });
        }
        if (this.hasMaterialPermission) {
            const material = _.map(materialCharts, chart => {
                const isSpumChart = [E_ANALYSIS_CHART.MATERIAL_FEATURE_EVALUATION, E_ANALYSIS_CHART.MATERIAL_PROPERTY_RANGE].includes(chart.chartType);
                return {
                    ...chart,
                    disabled: (!this.isSpumSearchModel && isSpumChart),
                    disabledReason: (!this.isSpumSearchModel && isSpumChart)
                        ? 'userOperation'
                        : '',
                };
            });
            return [
                ...material,
                ...general
            ];
        }
        return general;
    }
    /**
     * 控制图表状态：
     * 1. loading
     * 2. disabled
     */
    chartStatus = {};
    updateChartStatus(chartType, status, value) {
        this.chartStatus[chartType][status] = value;
    }
    /**
     * BI图表实例：导出时需要用到
     * 1. visual
     * 2. analysisSetting
     */
    chartInstance = {};
    updateChartInstance(chartType, type, value) {
        this.chartInstance[chartType][type] = value;
    }
    /***
     * 切换图表类型: 图1 - 图3 （材料）
     */
    chartRenderedType = {
        [E_ANALYSIS_CHART.MATERIAL_APPLICATION_EXPLORATION]: 'Doughnut',
        [E_ANALYSIS_CHART.MATERIAL_FEATURE_EVALUATION]: 'MultipleScatter',
        [E_ANALYSIS_CHART.MATERIAL_SRP_PROCESS_STUDY]: 'StackedColumn',
        [E_ANALYSIS_CHART.MATERIAL_PROPERTY_DISTRIBUTION]: 'TreeMap',
        [E_ANALYSIS_CHART.MATERIAL_PREPARATION_PROCESS_STUDY]: 'StackedColumn',
    };
    switchChartType(chartType, type) {
        this.chartRenderedType[chartType] = type;
    }
    phraseRenderedType = {
        [E_ANALYSIS_CHART.APD_Y_BENEFIT_PHRASE]: EBenefitTab.KEY,
        [E_ANALYSIS_CHART.ANCS_BENEFIT_PHRASE]: EBenefitTab.KEY,
        [E_ANALYSIS_CHART.BENEFIT_PHRASE_ADC_C]: EBenefitTab.KEY,
    };
    /**
     * 切换关键/所有功效：图4 - 图6 （通用）
     */
    switchPhraseType(chartType, type) {
        this.phraseRenderedType[chartType] = type;
    }
    /**
     * 特征属性图表：切换数值范围
     */
    chartDataRange = {
        [E_ANALYSIS_CHART.MATERIAL_FEATURE_EVALUATION]: 500,
        [E_ANALYSIS_CHART.MATERIAL_PROPERTY_RANGE]: 2000,
        [E_ANALYSIS_CHART.MATERIAL_SRP_PROPERTY_RANGE]: 2000,
    };
    dataRangeList = [500, 1000, 2000];
    get dataChangVisible() {
        return this.resultsInstance.total && this.resultsInstance.total > 100;
    }
    changeDataRange(chartType, range) {
        this.chartDataRange[chartType] = range;
    }
    reRenderChart(visualInstance, chartType) {
        const chart = visualInstance?.chart;
        if (!chart)
            return;
        const chartOption = getChartOption(visualInstance, chartType);
        chartOption.update();
    }
    async analysisTrack(eventType, chartType, eventDetail) {
        try {
            await ApiResults.analysisTrack({
                event_type: eventType,
                chart_type: chartType,
                event_detail_str: eventDetail,
            });
        }
        catch (error) {
            console.warn('analysisTrack error', error);
        }
    }
    async init() {
        Object.keys(E_ANALYSIS_CHART).forEach((key) => {
            this.chartStatus[E_ANALYSIS_CHART[key]] = {
                [E_CHART_STATUS.LOADING]: false,
                [E_CHART_STATUS.DISABLED]: false,
            };
            this.chartInstance[E_ANALYSIS_CHART[key]] = {
                [E_CHART_SETTING.VISUAL]: null,
                [E_CHART_SETTING.ANALYSIS_SETTING]: null,
            };
        });
        this.hasMaterialPermission = await hasMaterialPermission();
        const query = _.get(this.resultsInstance, 'doc_s.items', []);
        this.limitations = await queryLimitation(query).getLimitedItems();
    }
}
let analysisController = null;
export function getAnalysisControllerInstance() {
    if (!analysisController) {
        analysisController = new AnalysisController();
    }
    return analysisController;
}
