import { Playbooks } from '@eureka/services/src/types/playbook';
import { EResultsPattern } from '@eureka/services/src/types/results';
/**
 * 根据Playbook确定页面类型，页面类型用来控制页面菜单的配置
 * DESIGN的需要额外根据_type是DESIGN还是UTILITY确定
 */
export const ResultPatternMap = {
    [Playbooks.SMART]: EResultsPattern.DEFAULT,
    [Playbooks.NOVELTY]: EResultsPattern.DEFAULT,
    [Playbooks.LITERATURE]: EResultsPattern.PAPER,
    [Playbooks.LITERATURENOVELTY]: EResultsPattern.PAPER,
    [Playbooks.DESIGN]: EResultsPattern.DESIGN,
    [Playbooks.MATERIAL]: EResultsPattern.MATERIAL,
    [Playbooks.MATERIALNOVELTY]: EResultsPattern.MATERIAL,
    [Playbooks.LITERATUREMATERIAL]: EResultsPattern.MATERIAL_PAPER,
    [Playbooks.LITERATUREMATERIALNOVELTY]: EResultsPattern.MATERIAL_PAPER,
};
/**
 * 根据页面类型，动态渲染卡片组件
 * 目前只有2种，专利和文献
 */
export const ResultComponentMap = {
    [EResultsPattern.DEFAULT]: 'ProfilePatentForResults',
    [EResultsPattern.PAPER]: 'ProfilePaperForResults',
    [EResultsPattern.MATERIAL]: 'ProfilePatentForResults',
    [EResultsPattern.MATERIAL_PAPER]: 'ProfilePaperForResults',
    [EResultsPattern.UTILITY]: 'ProfilePatentForResults',
};
/**
 * 卡片类型
 */
export var E_SolutionState;
(function (E_SolutionState) {
    /**
     * 基础专利卡片
     */
    E_SolutionState["CommonPatent"] = "CommonPatent";
    /**
     * 基础文献卡片
     */
    E_SolutionState["CommonPaper"] = "CommonPaper";
    /**
     * 材料专利卡片（展示substance；pum有就展示；pum排序；不展示附图）
     */
    E_SolutionState["MaterialPatent"] = "MaterialPatent";
    /**
     * 材料文献卡片（展示substance；PUM有就展示）
     */
    E_SolutionState["MaterialPaper"] = "MaterialPaper";
    /**
     * 专利详情页的相似专利和引用专利列表(不展示功效)
     */
    E_SolutionState["PatentViewPatent"] = "PatentViewPatent";
})(E_SolutionState || (E_SolutionState = {}));
/**
 * 根据页面类型，确定卡片类型
 * 跨页面，不止结果页会用到
 */
export const SolutionCardMap = {
    [EResultsPattern.MATERIAL]: E_SolutionState.MaterialPatent,
    [EResultsPattern.MATERIAL_PAPER]: E_SolutionState.MaterialPaper,
    [EResultsPattern.PAPER]: E_SolutionState.CommonPaper,
    [EResultsPattern.DEFAULT]: E_SolutionState.CommonPatent,
    [EResultsPattern.UTILITY]: E_SolutionState.CommonPatent,
};
/**
 * 有手段展示手段，有PUM展示PUM，没有PUM则展示附图
 */
export const BaseStates = {
    showQuickSave: true,
    showQuickSavePaper: true,
    showSolutionTag: false,
    showMeansMethod: true,
    showImage: true,
    showPumAlways: false,
    showPumSorted: false,
};
const SolutionStructureStateMap = {
    [E_SolutionState.CommonPatent]: {
        ...BaseStates,
        showQuickSavePaper: false,
    },
    [E_SolutionState.MaterialPatent]: {
        ...BaseStates,
        showQuickSavePaper: false,
        showSolutionTag: true,
        showImage: false,
        showPumAlways: true,
        showPumSorted: true,
    },
    [E_SolutionState.PatentViewPatent]: {
        ...BaseStates,
        showQuickSavePaper: false,
        showMeansMethod: false,
    },
    [E_SolutionState.CommonPaper]: {
        ...BaseStates,
        showQuickSave: false,
        showSolutionTag: true,
        showImage: false,
    },
    [E_SolutionState.MaterialPaper]: {
        ...BaseStates,
        showQuickSave: false,
        showSolutionTag: true,
        showPumAlways: true,
        showImage: false,
    },
};
export function getSolutionStructureConfig(state) {
    return SolutionStructureStateMap[state];
}
/**
 * 批量保存到工作空间 - 默认选择前100条填入
 */
export const DefaultSelection = 100;
/**
 * 批量保存到工作空间 - folder上限2w
 */
export const BatchSaveFolderLimit = 20000;
