// @ts-nocheck
import materialConfig1 from './config/material-chart/chartConfig1.json';
import materialConfig2 from './config/material-chart/chartConfig2.json';
import materialConfig3 from './config/material-chart/chartConfig3.json';
import materialConfig4 from './config/material-chart/chartConfig4.json';
import materialConfig5 from './config/material-chart/chartConfig5.json';
import chartConfig1 from './config/chartConfig1.json';
import chartConfig2 from './config/chartConfig2.json';
import chartConfig3 from './config/chartConfig3.json';
import chartConfig4 from './config/chartConfig4.json';
import chartConfig5 from './config/chartConfig5.json';
import chartConfig6 from './config/chartConfig6.json';
import materialChart1 from '@/presentation/assets/icon/analysisMaterialChart1.svg';
import materialChart2 from '@/presentation/assets/icon/analysisMaterialChart2.svg';
import materialChart3 from '@/presentation/assets/icon/analysisMaterialChart3.svg';
import materialChart4 from '@/presentation/assets/icon/analysisMaterialChart4.svg';
import materialChart5 from '@/presentation/assets/icon/analysisMaterialChart5.svg';
// @ts-ignore
import chart1 from '@/presentation/assets/icon/analysisChart1.svg';
// @ts-ignore
import chart2 from '@/presentation/assets/icon/analysisChart2.svg';
// @ts-ignore
import chart3 from '@/presentation/assets/icon/analysisChart3.svg';
// @ts-ignore
import chart4 from '@/presentation/assets/icon/analysisChart4.svg';
// @ts-ignore
import chart5 from '@/presentation/assets/icon/analysisChart5.svg';
// @ts-ignore
import chart6 from '@/presentation/assets/icon/analysisChart6.svg';
import { EBenefitTab } from '@/types/result';
/**
 * 默认颜色
 */
export const COLOR_DEFAULT = '#0764E9';
// 图表配置色
export const VISUAL_PRE_DEFINE_COLORS = [
    [COLOR_DEFAULT],
    [COLOR_DEFAULT, '#27B4D1'],
    [COLOR_DEFAULT, '#27B4D1', '#A2C716'],
    [COLOR_DEFAULT, '#27B4D1', '#A2C716', '#CDCE12'],
    [COLOR_DEFAULT, '#27B4D1', '#A2C716', '#CDCE12', '#FABB27'],
    [COLOR_DEFAULT, '#27B4D1', '#A2C716', '#CDCE12', '#FABB27', '#FD865A'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73', '#D174D6'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73', '#D174D6', '#FA85B5'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73', '#D174D6', '#FA85B5', '#23A5A4'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73', '#D174D6', '#FA85B5', '#23A5A4', '#1E8EBB'],
    [COLOR_DEFAULT, '#1E8EBB', '#27B4D1', '#31AAAA', '#43B74B', '#A2C716', '#CDCE12', '#FABB27', '#FD865A', '#FF6C73', '#D174D6', '#FA85B5', '#23A5A4', '#1E8EBB', '#8F95AD']
];
export var IActionType;
(function (IActionType) {
    IActionType["EXPORT"] = "export";
    IActionType["SWITCHLAYOUT"] = "switch_layout";
    IActionType["DATARANGE"] = "data_range";
    // 关键/全部功效切换
    IActionType["BENEFIT_PHRASE"] = "benefit_phrase";
})(IActionType || (IActionType = {}));
export var E_ANALYSIS_CHART;
(function (E_ANALYSIS_CHART) {
    E_ANALYSIS_CHART["MATERIAL_APPLICATION_EXPLORATION"] = "adc_c";
    E_ANALYSIS_CHART["MATERIAL_PROPERTY_RANGE"] = "patent_property_waveform";
    E_ANALYSIS_CHART["MATERIAL_FEATURE_EVALUATION"] = "patent_property";
    E_ANALYSIS_CHART["MATERIAL_PROPERTY_DISTRIBUTION"] = "pum_prop_id_material";
    E_ANALYSIS_CHART["MATERIAL_PREPARATION_PROCESS_STUDY"] = "apd_y_ontology";
    /**
     * material srp专利列表视图 - 材料制备工艺研究
     */
    E_ANALYSIS_CHART["MATERIAL_SRP_PROCESS_STUDY"] = "MATERIAL_SRP_PROCESS_STUDY";
    // 性能区间的专利分布
    E_ANALYSIS_CHART["MATERIAL_SRP_PROPERTY_RANGE"] = "MATERIAL_SRP_PROPERTY_RANGE";
    E_ANALYSIS_CHART["TECHNICAL_PROBLEM"] = "technical_problem";
    E_ANALYSIS_CHART["TECH_DOMAIN_WORD_CLOUD"] = "tech_domain_word_cloud";
    E_ANALYSIS_CHART["APD_Y_BENEFIT_THEME"] = "apd_y_benefit_theme";
    // 技术效果布局趋势 - 所有功效
    E_ANALYSIS_CHART["APD_Y_BENEFIT_PHRASE"] = "apd_y_benefit_phrase";
    // 技术效果布局趋势 - 关键功效
    E_ANALYSIS_CHART["APD_Y_KEY_BENEFIT_PHRASE"] = "apd_y_key_benefit_phrase";
    E_ANALYSIS_CHART["BENEFIT_THEME_BENEFIT_PHRASE"] = "benefit_theme_benefit_phrase";
    // 技术效果*应用领域(三级) - 所有效果
    E_ANALYSIS_CHART["BENEFIT_PHRASE_ADC_C"] = "benefit_phrase_adc_c";
    // 技术效果*应用领域(三级) - 关键效果
    E_ANALYSIS_CHART["KEY_BENEFIT_PHRASE_ADC_C"] = "key_benefit_phrase_adc_c";
    // 技术效果玩家分布 - 所有功效
    E_ANALYSIS_CHART["ANCS_BENEFIT_PHRASE"] = "ancs_benefit_phrase";
    // 技术效果玩家分布 - 关键功效
    E_ANALYSIS_CHART["ANCS_KEY_BENEFIT_PHRASE"] = "ancs_key_benefit_phrase";
})(E_ANALYSIS_CHART || (E_ANALYSIS_CHART = {}));
// Material 图表
export const materialCharts = [
    {
        img: materialChart1.id,
        chartType: E_ANALYSIS_CHART.MATERIAL_APPLICATION_EXPLORATION,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_APPLICATION_EXPLORATION}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_APPLICATION_EXPLORATION}.desc`,
        config: materialConfig1,
        actions: [
            {
                type: IActionType.SWITCHLAYOUT,
                options: ['Doughnut', 'Column'],
            },
            {
                type: IActionType.EXPORT,
                options: ['PNG', 'Excel'],
            }
        ],
    },
    {
        img: materialChart2.id,
        chartType: E_ANALYSIS_CHART.MATERIAL_PROPERTY_RANGE,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PROPERTY_RANGE}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PROPERTY_RANGE}.desc`,
        config: materialConfig2,
        actions: [
            {
                type: IActionType.DATARANGE,
                options: [100, 600, 1000],
            },
            {
                type: IActionType.EXPORT,
                options: ['PNG', 'Excel'],
            }
        ],
    },
    {
        img: materialChart3.id,
        chartType: E_ANALYSIS_CHART.MATERIAL_FEATURE_EVALUATION,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_FEATURE_EVALUATION}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_FEATURE_EVALUATION}.desc`,
        config: materialConfig3,
        actions: [
            {
                type: IActionType.DATARANGE,
                options: [100, 600, 1000],
            },
            {
                type: IActionType.EXPORT,
                options: ['PNG', 'Excel'],
            }
        ],
    },
    {
        img: materialChart4.id,
        chartType: E_ANALYSIS_CHART.MATERIAL_PROPERTY_DISTRIBUTION,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PROPERTY_DISTRIBUTION}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PROPERTY_DISTRIBUTION}.desc`,
        config: materialConfig4,
        actions: [
            {
                type: IActionType.SWITCHLAYOUT,
                options: ['TreeMap', 'Table'],
            },
            {
                type: IActionType.EXPORT,
                options: ['PNG', 'Excel'],
            }
        ],
    },
    {
        img: materialChart5.id,
        chartType: E_ANALYSIS_CHART.MATERIAL_PREPARATION_PROCESS_STUDY,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PREPARATION_PROCESS_STUDY}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PREPARATION_PROCESS_STUDY}.desc`,
        config: materialConfig5,
        disabled: false,
        actions: [
            {
                type: IActionType.SWITCHLAYOUT,
                options: ['StackedColumn', 'Table'],
            },
            {
                type: IActionType.EXPORT,
                options: ['PNG', 'Excel'],
            }
        ],
    }
];
// Eureka 基础图表
export const generalCharts = [
    {
        img: chart1.id,
        chartType: E_ANALYSIS_CHART.TECHNICAL_PROBLEM,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.TECHNICAL_PROBLEM}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.TECHNICAL_PROBLEM}.desc`,
        config: chartConfig1,
    }, {
        img: chart2.id,
        chartType: E_ANALYSIS_CHART.TECH_DOMAIN_WORD_CLOUD,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.TECH_DOMAIN_WORD_CLOUD}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.TECH_DOMAIN_WORD_CLOUD}.desc`,
        config: chartConfig2,
    }, {
        img: chart3.id,
        chartType: E_ANALYSIS_CHART.APD_Y_BENEFIT_THEME,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.APD_Y_BENEFIT_THEME}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.APD_Y_BENEFIT_THEME}.desc`,
        config: chartConfig3,
    }, {
        img: chart4.id,
        chartType: E_ANALYSIS_CHART.APD_Y_BENEFIT_PHRASE,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.APD_Y_BENEFIT_PHRASE}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.APD_Y_BENEFIT_PHRASE}.desc`,
        config: chartConfig4,
        actions: [{
                type: IActionType.BENEFIT_PHRASE,
            }],
    },
    {
        img: chart5.id,
        chartType: E_ANALYSIS_CHART.BENEFIT_PHRASE_ADC_C,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.BENEFIT_PHRASE_ADC_C}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.BENEFIT_PHRASE_ADC_C}.desc`,
        config: chartConfig5,
        actions: [{
                type: IActionType.BENEFIT_PHRASE,
            }],
    }, {
        img: chart6.id,
        chartType: E_ANALYSIS_CHART.ANCS_BENEFIT_PHRASE,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.ANCS_BENEFIT_PHRASE}.title`,
        descKey: `analysis.charts.${E_ANALYSIS_CHART.ANCS_BENEFIT_PHRASE}.desc`,
        config: chartConfig6,
        actions: [{
                type: IActionType.BENEFIT_PHRASE,
            }],
    }
];
// 关键/全部功效列tab
export const phrase_list = [{
        name: EBenefitTab.KEY,
    }, {
        name: EBenefitTab.ALL,
    }];
// 可以进行关键/全部功效切换的图表
export const PhraseSwitchChartList = [
    E_ANALYSIS_CHART.APD_Y_BENEFIT_PHRASE,
    E_ANALYSIS_CHART.ANCS_BENEFIT_PHRASE,
    E_ANALYSIS_CHART.BENEFIT_PHRASE_ADC_C
];
export const PhraseSwitchChartMap = {
    [E_ANALYSIS_CHART.APD_Y_BENEFIT_PHRASE]: E_ANALYSIS_CHART.APD_Y_KEY_BENEFIT_PHRASE,
    [E_ANALYSIS_CHART.ANCS_BENEFIT_PHRASE]: E_ANALYSIS_CHART.ANCS_KEY_BENEFIT_PHRASE,
    [E_ANALYSIS_CHART.BENEFIT_PHRASE_ADC_C]: E_ANALYSIS_CHART.KEY_BENEFIT_PHRASE_ADC_C,
};
/**
 * Material srp专利列表视图
 */
export const materialSrpCharts = [
    /**
     * material srp 专利列表视图-性能区间的专利分布
     */
    {
        chartType: E_ANALYSIS_CHART.MATERIAL_SRP_PROPERTY_RANGE,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PROPERTY_RANGE}.title`,
        config: materialConfig2,
        actions: [
            {
                type: IActionType.DATARANGE,
                options: [100, 600, 1000],
            }
        ],
    },
    {
        chartType: E_ANALYSIS_CHART.MATERIAL_SRP_PROCESS_STUDY,
        titleKey: `analysis.charts.${E_ANALYSIS_CHART.MATERIAL_PREPARATION_PROCESS_STUDY}.title`,
        config: materialConfig5,
    }
];
