export const messages = {
    cn: {
        title: '工具',
        desc: '我是您的AI小助手芽仔，这里有为您准备的实用工具包，让一切都变得轻松~ 快来探索吧！',
        project: {
            title: '技术预研报告',
            desc: '基于全球专利、文献数据，2分钟快速汇总研发信息，提供建议研发方向。',
        },
        disclosure: {
            title: '交底书助手',
            desc: '1分钟内，理解扩展你的技术想法，并完成撰写交底书。',
        },
    },
    en: {
        title: 'Tools',
        desc: 'Hi there! I\'m your AI assistant Hiro. Here\'s the practical toolkit I\'ve prepared for you. Explore it now!',
        project: {
            title: 'Technical Research Report',
            desc: 'Exploring a new technology area? Create a research report using patents and literature analysis.',
        },
        disclosure: {
            title: 'Invention Disclosure',
            desc: 'Quickly expand and draft your technology ideas into a AI-assisted disclosure document.',
        },
    },
    jp: {
        title: 'ツール',
        desc: 'こんにちは!私はあなたのAIアシスタントHiroです。ここであなたのために準備した実用的なツールキットがあります。今すぐ探検しましょう!',
        project: {
            title: '技術予備調査レポート',
            desc: '全球の特許・文献データに基づき、2分で研究情報を迅速に集約し、研究方向を提案。',
        },
        disclosure: {
            title: '明細書作成アシスタント',
            desc: '1分以内で技術アイデアを理解し拡張し、開示書の作成を完了。',
        },
    },
    tw: {
        title: '工具',
        desc: '我是您的AI小助手芽仔，這裡有為您準備的實用工具包，讓一切都變得輕鬆~ 快來探索吧！',
        project: {
            title: '技術預研報告',
            desc: '基於全球專利、文獻數據，2分鐘快速彙總研發資訊，提供建議研發方向。',
        },
        disclosure: {
            title: '交底書助手',
            desc: '1分鐘內，理解並擴展您的技術想法，完成交底書撰寫。',
        },
    },
};
